.container {
  @apply w-full block-cms-container-mobile mb-7;
  padding: 0 !important;
  @screen md {
    @apply block-cms-container-tablet;
  }
  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.container img {
  @apply m-auto;
}
