.container {
  @apply flex flex-row flex-wrap;
}

.menuContainer {
  @apply sticky top-0 self-start hidden md:block md:pl-11;
  flex: 3 1;
}

.menu {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.blocks {
  @apply w-full max-w-full text-primaryA bg-primaryB;
  flex: 12 1;
}
.blocks > * {
  @apply box-border;
}

@screen md {
  .menuContainer + .blocks {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
}
