.customSelectContainer {
  @apply font-inter w-full text-primaryA;
}

.sizeContainer {
  @apply flex flex-row justify-between w-full items-center;
}

.sizeValue {
  @apply text-sm uppercase;
}

.outOfStock {
  @apply line-through;
  color: var(--grey500);
}

.sizeInfo {
  @apply flex flex-row items-center ml-2;
  @screen md {
    @apply flex-row-reverse ml-0;
  }
}

[dir='rtl'] .sizeInfo {
  @apply mr-2 ml-0;
  @screen md {
    @apply mr-0;
  }
}

.sizeInfoText {
  @apply ml-2;
  @screen md {
    @apply ml-0 mr-2 text-right;
  }
}

[dir='rtl'] .sizeInfoText {
  @apply mr-2 ml-0;
  @screen md {
    @apply mr-0 ml-2 text-left;
  }
}

.label {
  @apply flex items-center text-right ml-2 text-sm;
}

[dir='rtl'] .label {
  @apply mr-2 ml-0 text-left;
}

.uniqueSizeContainer {
  @apply h-12 flex items-center justify-between border border-grey300 box-border p-4;
}
.uniqueSize {
  @apply text-sm;
}

.noStock {
  @apply flex flex-row items-center text-sm;
  font-size: 0.875rem;
}
.noStock > svg {
  @apply ml-1;
}

[dir='rtl'] .noStock > svg {
  @apply mr-1 ml-0;
}

.sizeContainer.lowStock,
.uniqueSizeContainer.lowStock {
  @apply text-lowstock;
}
.sizeContainer.empty,
.uniqueSizeContainer.empty {
  @apply text-grey300;
}
