.container {
  @apply w-full border-primaryA relative py-0 px-2.5 md:px-4 xl:px-11;
}

.content {
  @apply relative flex flex-col items-center justify-center w-full p-0 m-0 border-primaryA;
  border-top-width: 0.0625rem;
  min-height: 10rem;
}
.container:nth-child(2) .content {
  @apply border-none lg:border-solid lg:border-grey300;
}

.row {
  @apply relative flex flex-row flex-wrap items-start justify-start w-full py-0 m-0 opacity-100;
}

.filters {
  @apply box-border fixed bottom-0 z-50 invisible w-full h-full bg-primaryB;
  width: 85vw;
  left: -100%;
  transition: visibility 0.25s, left 0.25s;
  @screen lg {
    @apply sticky left-0 z-10 overflow-y-scroll;
    width: 0;
    height: calc(100vh - 7.9375rem);
    top: 7.9375rem;
    transform: none;
    transition: visibility 0.25s, width 0.25s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      @apply hidden;
    }
  }
  @screen xl {
    height: calc(100vh - 4.25rem);
    top: 4.25rem;
  }
}
.filters.open {
  @apply left-0 visible;
  @screen lg {
    @apply mr-6;
    width: 14.75rem;
  }
  @screen xl {
    width: 15.625rem;
  }
}

.layout {
  @apply fixed top-0 left-0 z-40 w-full h-full opacity-0 cursor-pointer;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.3s linear;
  animation-fill-mode: forwards;
}

.cards {
  @apply flex-1 w-full relative lg:mt-0.5;
}

.loading {
  @apply fixed bottom-0 left-0 z-50 w-full h-full bg-white bg-opacity-0;
}

.msg {
  @apply text-lg font-medium text-center uppercase border-t border-primaryA pt-14 pb-11 lg:pb-0;
}

.header {
  @apply py-5 lg:py-7 text-sm text-center uppercase border-t font-inter border-grey300;
}

.query {
  @apply font-medium;
}

.filtersContainer {
  @apply relative w-full h-full bg-primaryB;
}

.filtersList {
  @apply relative w-full h-full overflow-x-hidden;
  padding-bottom: 3.125rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.filtersList::-webkit-scrollbar {
  @apply hidden;
}
.filtersList > div:last-child {
  @apply lg:border-b;
}

.filterAction {
  @apply absolute bottom-0 z-30 flex flex-row items-center w-full bg-primaryB border-t lg:hidden border-grey300;
}

.buttonAction {
  @apply w-full uppercase font-medium text-center py-4 px-2.5 text-primaryA whitespace-nowrap;
}

.separatorAction {
  @apply h-6 bg-grey300;
  width: 0.063rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
