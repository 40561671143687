.container {
  @apply flex h-full bg-primaryB;
  margin: 0.6rem;

  @screen xl {
    @apply w-full p-0 m-0 mx-auto;
    max-width: 90rem;
  }
}

.form {
  @apply flex w-full h-10;
  @screen xl {
    @apply h-auto;
  }
}

.inputContainer {
  @apply relative flex w-full;
  border-radius: 50px;
  background-color: #f3f3f3;

  @screen xl {
    @apply bg-primaryB pl-6 rounded;
  }
}

.inputBox {
  @apply relative  w-full h-full bg-transparent  rounded;

  @screen xl {
    @apply h-10 mt-6 mb-5  pb-1 bg-grey50;
  }

  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
}

.input {
  @apply absolute w-full  h-full font-inter text-base text-primaryA bg-transparent rounded pl-1;
  line-height: 1.375rem;

  @screen xl {
    @apply text-sm pl-10;
  }
}
[dir='rtl'] .input {
  @apply pr-10;
  padding-left: unset;
}

.ghostSuggestion {
  @apply absolute flex items-center bg-transparent text-grey500 select-none;
}

.input:focus:not([value='']) {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.input::placeholder {
  @apply text-grey600;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.input:focus:placeholder-shown {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.input:placeholder-shown {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.input[placeholder] {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.input::-moz-placeholder {
  /* Firefox 19+ */
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.input:-ms-input-placeholder {
  /* IE 10+ */
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}
.input:-moz-placeholder {
  /* Firefox 18- */
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.close {
  @apply relative opacity-90 text-center font-medium;

  @screen xl {
    @apply flex items-center justify-center h-10 w-10 mt-6 mx-6 bg-grey50 rounded;
  }
}

.closeLabel {
  @apply block pl-2;
  color: var(--grey600);

  @screen xl {
    @apply hidden;
  }
}
[dir='rtl'] .closeLabel {
  @apply pr-2 pl-0;
}

.cross {
  @apply hidden;

  @screen xl {
    @apply inline;
  }
}

.loupe {
  @apply flex items-center px-2;

  @screen xl {
    @apply absolute px-4  pb-9  z-10;
    padding-top: 2.375rem;
  }
}

.newSearchCenter {
  @apply hidden;

  @screen xl {
    @apply inline px-12 py-3 mt-5 text-sm font-medium text-center uppercase border border-gray-800 typo-inter-text text-primaryA;
  }
}

.inputSearch {
  @apply w-full;
}

.erase {
  @apply hidden text-grey600 underline text-sm mt-1;

  @screen xl {
    @apply absolute inline right-6  mt-auto;
    top: 55%;
    transform: translateY(-55%);
  }
}

[dir='rtl'] .erase {
  @screen xl {
    @apply left-10;
    right: unset;
  }
}
