.scrollTop {
  @apply hidden fixed bottom-7 right-2.5 px-3 py-1.5 border border-primaryA bg-primaryB cursor-pointer;
  z-index: 18;
  @screen md {
    @apply bottom-10 right-5 py-3;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}
[dir='rtl'] .scrollTop {
  @apply left-2.5;
  right: unset;
  @screen md {
    @apply left-5;
  }
}

.showScroll {
  @apply flex opacity-0;
  animation: fadeIn 0.5s linear;
  animation-fill-mode: forwards;
}

.arrowThin {
  @apply relative;
  border-left: 0.75rem solid black;
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
}

.arrowThin:after {
  @apply absolute;
  content: '';
  border-left: 0.75rem solid #fff;
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
  top: -0.75rem;
  right: 0.0625rem;
}

.arrowUp {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
