.container {
  @apply w-full relative;
}

@screen md {
  .container::-webkit-scrollbar {
    width: 0.4375rem;
  }

  .container::-webkit-scrollbar-thumb {
    @apply rounded;
    background-color: rgba(0, 0, 0, 0);
  }

  .container:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.container h3 {
  @apply py-2 text-primaryA font-medium uppercase hidden md:block;
  font-size: 0.8125rem;
}

.content li,
.content.oldDescription p {
  @apply py-0 font-inter text-sm;
}
.content.oldDescription li:not(:empty) {
  @apply pt-1 pb-1.5;
}

.careImg {
  @apply flex flex-row flex-wrap justify-start items-center relative pt-2;
}
.picto {
  @apply h-5 mt-2 pl-1 pr-1;
}
.picto:after {
  @apply block relative w-5 h-5 bg-primaryB border border-grey300;
  content: ' ';
  top: -1.1875rem;
  left: -0.1875rem;
}

.careText {
  @apply flex flex-col relative pt-2;
}
.legend {
  @apply typo-inter-text text-sm;
}
