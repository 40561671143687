.container {
  @apply flex justify-start flex-1 cursor-default;
  font-size: 0.6875rem;
}

.leftTags {
  @apply flex;
}

.tag {
  @apply mr-1 px-2 text-xxs font-normal text-primaryB border uppercase;
  line-height: 1.125rem;
}
[dir='rtl'] .tag {
  @apply ml-1 mr-0;
}
.offer {
  @apply text-promotion border-promotion;
}
.greenable {
  @apply text-greenable border-greenable;
}
.charitable {
  @apply text-charitable border-charitable;
}
.exclu {
  @apply text-grey600 border-grey600;
}
.new {
  @apply text-primaryA border-primaryA;
}
.special {
  color: #896cc3;
  border-color: #896cc3;
}
.special.onCard {
  @apply cursor-default;
  @screen lg {
    max-width: 75%;
  }
}
.special.productDetails {
  @apply cursor-pointer;
}
