.btnFacebookContainer {
  @apply flex flex-col justify-center items-start flex-1 w-full relative mb-8 mt-2.5  pb-4 px-0 border-grey300 border-t-0;

  @screen md {
    @apply w-full mb-0  pb-0 mt-0;
  }
}
.btnFacebookContainer p {
  @apply sm:mt-0 text-primaryA text-sm bg-primaryB;
  top: initial;
}
.btnFacebook {
  @apply w-full flex justify-between text-sm mt-3 mx-0 bg-primaryB text-primaryA font-normal uppercase rounded-none border-primaryA;
  height: 3rem;
  line-height: 1.375rem;
  margin-bottom: 1.25rem;
  @screen md {
    @apply mt-2.5;
  }
}

.btnFacebook:hover {
  @apply bg-primaryB;
}

.btnFacebook > span:first-child {
  @apply w-6;
}
.btnFacebook > span:last-child {
  margin-right: calc(50% - 2.25rem);
}

.btnFacebook > span > svg > path:first-child {
  transition: fill 0.2s ease-in-out;
}

.btnFacebook:hover > span > svg > path:first-child {
  fill: #415a93;
}
