.imgContainer {
  @apply mr-6;
}
[dir='rtl'] .imgContainer {
  @apply ml-6 mr-0;
}

.container {
  @apply flex;
}

.isNew {
  @apply font-inter text-grey600;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.productBrand {
  @apply font-inter text-primaryA font-medium uppercase;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.productName {
  @apply mt-1 font-inter text-grey600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.productPrice {
  @apply mt-2;
}

.productSize {
  @apply mt-2 font-inter text-sm text-primaryA;
}
