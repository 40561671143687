.container {
  @apply w-full relative block-cms-container-mobile;
  @screen md {
    @apply block-cms-container-tablet;
  }
  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.brand {
  @apply flex items-center w-full pt-4 pb-1 border-t  border-primaryA;

  @screen md {
    @apply pt-7 pb-2;
  }
  @screen lg {
    @apply pb-0;
  }
}

.descriptionContainer {
  @apply w-full lg:w-1/2 flex flex-col items-center m-auto;
  padding: 0 5%;
}

.breadcrumbs {
  @apply font-lora text-primaryA capitalize;
  font-size: 0.625rem;
  margin-bottom: 0.625rem;
}

.breadcrumbs ul {
  @apply flex;
}

.link {
  @apply text-grey600;
}

.link:hover {
  @apply text-primaryA;
}

.title {
  @apply typo-gilda-title text-center;
}

.description {
  @apply font-lora text-xs text-primaryA text-center mt-1 flex flex-col items-center;
}

.readMore {
  @apply inline-block	font-inter text-xs font-medium mt-4 cursor-pointer uppercase border-b-2 border-primaryA border-solid;
}

.arrow {
  @apply ml-2 inline-block;
  transition: transform 0.3s ease;
}

[dir='rtl'] .arrow {
  @apply mr-2 ml-0;
}

.arrowUp {
  transform: rotate(-90deg) translateX(0%);
}

.arrowDown {
  transform: rotate(90deg) translateX(-30%);
}

.mediaContainer {
  @apply w-1/2;
}

.mediaContainer img {
  width: 100%;
}

.details {
  @apply mx-auto text-justify;
  max-width: 53.125rem;
}
/* To fix interview img in the meantime,  clear brands rich text */
.details img {
  width: 100% !important;
  height: auto !important;
}

.separator {
  border-bottom: 0.1875rem solid black;
  margin-top: 1.3125rem;
  margin-bottom: 1.875rem;
  width: 2.1875rem;
}
