.container {
  @apply relative mt-5 mb-6 md:mb-0 p-5 bg-grey50;
}

.action {
  @apply w-full mt-5 text-primaryA bg-primaryB border border-primaryA bg-transparent shadow-none;
}
.action:hover {
  @apply bg-transparent border-grey300;
}

.notice {
  @apply mt-5 text-sm underline cursor-pointer;
}
