/* .fit {
  min-height: calc(100vh - 4.1875rem);

  @screen md {
    min-height: calc(100vh - 32.1875rem);
  }
} */

.rich-text {
  font-size: 0.75rem;
}

.page_content > .rich-text {
  @apply w-full;
}

.rich-text .h1 {
  @apply mt-2 border-solid border-b pb-5 text-primaryA text-base font-medium uppercase;
  border-color: var(--grey300);
}

.rich-text .h2 {
  @apply mt-4 p-3 text-primaryA text-sm font-medium uppercase;
  font-size: 0.75rem;
}

@screen md {
  .rich-text .h1 {
    @apply text-sm;
  }
  .rich-text .h2 {
    @apply p-0 text-xs;
  }
}

.rich-text .h1 + p {
  margin-top: 1.125rem;
}

.rich-text li:before {
  @apply inline-block w-1 h-1 bg-primaryA mr-7;
  content: '';
  border-radius: 100%;
}

[dir='rtl'] .rich-text li:before {
  @apply ml-7 mr-0;
}

.title,
p b {
  @apply block pt-3 pb-0.5;
}
.title strong,
p b {
  font-size: 0.875rem;
}
.title {
  @apply pb-2;
}
.title strong {
  @apply uppercase;
}

.rich-text a {
  text-decoration: underline;
}
.rich-text a:hover {
  color: #000;
}

.rich-text .s-table th {
  @apply text-xxs border-b-2 border-solid uppercase;
  color: var(--grey600);
  padding: 0.625rem 0.3125rem;
  @screen md {
    padding: 0.875rem 0;
  }
}

.rich-text .s-table td {
  @apply border-b border-solid text-grey600;
  padding: 0.875rem 0;
  @screen md {
    color: var(--grey500);
  }
}

.rich-text .s-table th,
.rich-text .s-table td {
  @apply text-center md:text-sm;
  font-size: 0.75rem;
}

.rich-text .cms-main-section {
  @apply font-medium uppercase text-center pb-0.5 md:pb-2.5;
  font-size: 0.75rem;
}

.rich-text .cms-home-title-s-chronicle {
  @apply pb-2.5 font-gilda text-2xl text-center font-normal;
  font-size: 1.5625rem;
  line-height: 1.8125rem;
  @screen md {
    @apply text-4xl leading-9;
  }
}

.rich-text .cms-main-description {
  @apply pb-5 text-center font-lora font-normal;
  max-width: 38.75rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0 auto;
}

.rich-text .cms-home-border-link {
  @apply w-full p-2.5 font-inter text-xs text-primaryA text-center font-medium bg-primaryB border-2 border-primaryA uppercase;
  max-width: 15.25rem;
  transition: background-color 0.3s;
}
.rich-text .cms-home-border-link:hover {
  @apply text-primaryB bg-primaryA;
}
.rich-text .cms-home-border-link:hover a {
  @apply text-primaryB;
}

.home_large_block .rich-text .cms-home-border-link {
  @apply text-primaryB bg-primaryA;
}
.home_large_block .rich-text .cms-home-border-link:hover {
  @apply text-primaryA bg-primaryB;
}
.home_large_block .rich-text .cms-home-border-link:hover a {
  @apply text-primaryA;
}

.rich-text .cms-home-title-xs {
  @apply mb-2.5 text-center text-xs font-inter font-medium uppercase;
}

.rich-text .cms-home-title-xs-chronicle {
  @apply mb-2.5 font-gilda text-center font-normal;
  font-size: 1.5625rem;
  line-height: 1.8125rem;
}

.rich-text .cms-home-title-xl-chronicle {
  @apply pb-2.5 text-4xl font-gilda text-center font-normal;
  @screen md {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
}

.rich-text .cms-home-title-xs-left {
  @apply text-xs font-inter font-normal;
}

.rich-text .cms-home-description {
  @apply pb-5 text-center text-sm font-inter font-normal md:block;
}

/* Custom block style */
.home_large_block .cms-home-description,
.home_medium_block .cms-home-description,
.home_small_block .cms-home-description {
  @apply hidden md:block;
}
.home_large_block .cms-home-border-link,
.home_medium_block .cms-home-border-link,
.home_small_block .cms-home-border-link {
  @apply hidden md:block;
}

.header_page .cms-home-description {
  @apply font-lora;
}
.header_page .cms-main-description {
  @apply typo-lora-text-m;
}

.slide_block .rich-text .cms-home-title-s-chronicle {
  @apply pb-1.5;
}

.page_content .rich-text .cms-home-title-s-chronicle:only-child {
  @apply pt-2.5;
}

.guide_block .rich-text .cms-home-description {
  @apply text-justify;

  @screen md {
    @apply mb-5;
    padding: 0 14%;
  }
}

.description_listing_header {
  @apply mb-0 !important;
}

/* Size Guide */
#size_guide .rich-text {
  max-width: calc(100vw - 2rem);
  @screen md {
    @apply w-full;
  }
}

#size_guide .rich-text .s-table {
  @apply block overflow-x-auto;

  @screen md {
    @apply table overflow-hidden;
  }
}

#size_guide .rich-text .h1 {
  @apply text-left border-solid border-b font-medium leading-4 text-base uppercase;
}

#size_guide .rich-text .s-table th {
  @apply border border-solid;
  min-width: 3rem;

  @screen md {
    @apply border-t-0 border-r-0 border-l-0 border-b-2 min-w-0 text-xxs;
  }
}

#size_guide .rich-text .s-table td {
  @apply border border-solid;
  min-width: 3rem;

  @screen md {
    @apply border-t-0 border-r-0 border-l-0 min-w-0;
  }
}

#size_guide .rich-text .s-table th:first-child,
#size_guide .rich-text .s-table td:first-child {
  @apply text-left pl-2 font-medium;
  min-width: 9.25rem;
}

/* focus-visible */
.js-focus-visible :not(.focus-visible):focus {
  @apply outline-none;
}
.js-focus-visible .focus-visible:focus {
  @apply ring outline-none;
}

/* Algolia */
input[type='search' i]:enabled:read-write:-webkit-any(
    :focus,
    :hover
  )::-webkit-search-cancel-button {
  @apply w-4 h-4 mr-4 cursor-pointer;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 3.5L3.5 10.5' stroke='%23121212' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.5 3.5L10.5 10.5' stroke='%23121212' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: 16px 16px;

  @screen xl {
    display: none;
  }
}

[dir='rtl']
  input[type='search' i]:enabled:read-write:-webkit-any(
    :focus,
    :hover
  )::-webkit-search-cancel-button {
  @apply ml-4 mr-0;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

input[type='search']:not([value=''])::-webkit-search-cancel-button {
  display: block;
}

/* Display float block-cms */
@screen md {
  .home_medium_block + .home_small_block + .home_small_block + section,
  .home_medium_block + .home_small_block + .home_small_block + div {
    clear: left;
  }
}

/* Footer  rich text */
.footer-rich-text .rich-text,
.footer-rich-text .rich-text .cms-home-description:last-of-type {
  @apply my-2 py-0;
  max-width: 100rem;
}
.footer-rich-text .rich-text a:hover {
  @apply text-grey300;
}

/* Picto care  rich text */
.iconCareTabList {
  display: flex !important;
  @apply flex-row flex-wrap justify-start items-center relative pt-2;
}
.iconCareTabImg {
  @apply h-5 mt-2 pl-1 pr-1;
}

/* Msg push sales in listing*/
.button_slider + .msg_push_seeAll,
.home_item_block + .msg_push_seeAll {
  @apply hidden;
}

/* Alma Payment Modal */

.alma-eligibility-modal-desktop-modal {
  @apply text-sm;
}
