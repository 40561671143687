.container {
  @apply overflow-auto px-2.5 md:px-5 md:py-0;
}
.container:hover {
  @screen md {
    cursor: url('/img/cross.svg'), pointer;
  }
}

.miniaturesContainer {
  @apply fixed overflow-scroll left-0 bottom-0 w-full;
  padding: 0.3125rem;
  margin-bottom: 0.625rem;
  @screen md {
    @apply w-auto top-0 p-5 left-auto;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.miniaturesContainer::-webkit-scrollbar {
  display: none;
}

.imgContainer {
  @apply h-full;
  min-width: 100vw;
  width: max-content;
  max-width: 300vw;

  @screen md {
    @apply flex justify-center;
    max-width: max-content;
  }
}
