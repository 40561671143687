.selectorContainer {
  @apply relative flex items-center;
  margin-right: 1.563rem;
}

.valueSelect {
  @apply flex h-full justify-center items-center text-grey600 font-inter text-sm uppercase;

  @screen md {
    font-size: 0.75rem;
    text-shadow: 0.2px 0.2px 0 rgb(0 0 0 / 70%);
  }
}

.valueSelect > span {
  @apply font-medium xl:font-normal;
  font-size: 0.75rem;
  @screen xl {
    font-size: 0.688rem;
  }
}

.arrowDown {
  @apply hidden absolute;
  right: 0.625rem;
  width: 0.625rem;
  height: 0.625rem;

  @screen xl {
    @apply block ml-1 static;
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(90deg) translateX(-10%);
  }
}

[dir='rtl'] .arrowDown {
  @screen xl {
    @apply ml-0 mr-1;
  }
}

.selectField {
  @apply absolute top-0 h-full w-full xl:px-3 cursor-pointer border-0 bg-transparent text-transparent;
}
.selectField option {
  @apply text-primaryA;
}
