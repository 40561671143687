.container {
  @apply w-full box-border relative my-0 mx-0 py-0 px-0 md:mt-16 md:px-4 xl:px-11;
}
.container.noPadding {
  @screen md {
    @apply mt-0 px-0;
  }
  @screen xl {
    @apply px-0;
  }
}

.content {
  @apply flex flex-col justify-center items-center w-full relative;
}
.content.titleBorder {
  @apply border-t border-grey300 lg:border-primaryA;
}

.title {
  @apply my-8 py-0 px-4 text-sm font-inter font-medium uppercase text-primaryA bg-primaryB;

  @screen md {
    @apply mt-0 mb-6 font-normal font-gilda text-2xl normal-case transform -translate-y-1/2;
  }
}

.row {
  @apply flex flex-row justify-start items-start flex-wrap w-full relative;
}
.col {
  @apply w-full lg:w-1/3 box-border;
  margin-bottom: 3rem;
  @screen lg {
    margin-bottom: 3.125rem;
  }
}
.col.half {
  @apply w-1/2 lg:w-1/3;
}

.customInsertRow {
  @apply w-full;
}

.loadMoreContainer {
  @apply relative mb-14 lg:mb-0;
}

.loadmore {
  @apply text-sm text-center text-primaryA;
  margin: 0.5rem 0 0;
}
.loadmore mark {
  @apply bg-transparent font-extrabold;
}

.seemoreButton {
  @apply w-full h-12 text-sm uppercase font-medium;
  @screen md {
    max-width: 27.125rem;
  }
}

.loading {
  min-height: 3rem;
}

.navigationContainer {
  @apply flex flex-col lg:flex-row items-center md:justify-between lg:justify-center w-full lg:mt-4 lg:pb-3;
}

.paginationTypeContainer {
  @apply flex flex-wrap items-center relative mb-4 lg:mb-0 right-auto xl:absolute xl:right-0;
}
.paginationTypeContainer::after {
  @apply absolute right-4 bottom-4;
  content: '';
  width: 0.313rem;
  height: 0.313rem;
  box-shadow: 0.0625rem 0.0625rem 0 var(--primaryA);
  transform: rotate(45deg);
}
[dir] .paginationTypeContainer::after {
  @apply left-4 right-auto;
}

.labelSelector {
  @apply uppercase text-primaryA font-medium;
  font-size: 0.75rem;
}

.selector {
  @apply block relative border text-grey600 text-sm border-grey300 bg-primaryB cursor-pointer;
  margin: 0 0 0 0.315rem;
  padding: 0.45rem 1.575rem 0.45rem 0.625rem;
}
[dir] .selector {
  padding: 0.45rem 0.625rem 0.45rem 1.575rem;
}
