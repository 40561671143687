.container {
  @apply w-full box-border relative;
  @screen md {
    @apply mt-16 mb-0 px-4;
  }
  @screen xl {
    @apply px-11;
  }
}

.content {
  @apply flex flex-col justify-center items-center w-full relative border-t lg:border-primaryA;
}

.title {
  @apply mb-6 pt-6 px-4 font-gilda text-2xl text-primaryA bg-primaryB;
  @screen lg {
    @apply py-0 transform -translate-y-1/2;
  }
}

.slider {
  @apply relative;
  width: 90% !important;
}
.slide + div {
  @apply pb-7;
}

.arrowLeft,
.arrowRight {
  @apply w-12 h-full absolute top-0 z-10;
}
.arrowLeft {
  @apply left-0;
}
.arrowRight {
  @apply right-0;
}
.arrowLeft::before,
.arrowRight::before {
  @apply w-7 h-7 absolute border-t border-l border-solid border-primaryB;
  content: '';
  box-shadow: -0.0625rem -0.0625rem 0 black;
  @screen md {
    margin: auto -0.3125rem 0;
  }
}
.arrowLeft::before {
  transform: rotate(-45deg);
}
.arrowRight::before {
  transform: translateX(-50%) rotate(135deg);
}

.jsDisabled {
  @apply flex flex-col;
}
.jsDisabledBlocks {
  @apply pb-3;
}
