.breadcrumb {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 0.9375rem 0 0;
  @apply flex flex-row items-center;
}

.element {
  @apply font-inter text-grey600 hidden;
  font-size: 0.75rem;
  line-height: 1;

  @screen md {
    @apply flex flex-row items-center;
    font-size: 0.625rem;
  }
}

.breadcrumbLastItem .element:last-child {
  @apply flex flex-row items-center;
}

.breadcrumb:not(.breadcrumbLastItem) .element:nth-last-child(2),
.breadcrumb:not(.breadcrumbLastItem) .element:nth-last-child(3) {
  @apply flex flex-row items-center;
}
.breadcrumb:not(.breadcrumbLastItem) .element:nth-last-child(2) .slash {
  @apply hidden md:inline;
}
.breadcrumb:not(.breadcrumbLastItem)
  .element:nth-last-child(2):not(:first-child)
  .chevron {
  @apply hidden;
}

.link:hover {
  @apply text-primaryA;
}

.title {
  @apply ml-1 md:m-0 capitalize;
}

[dir='rtl'] .title {
  @apply mr-1 ml-0;
}

.chevron {
  @apply inline md:hidden;
}

.chevron svg {
  @apply w-4 h-4 m-auto;
  transform: rotate(-90deg);
}
