.container {
  @apply px-2.5 md:px-0 pt-10 border-t-2 border-solid border-grey300;
}

.title {
  @apply font-gilda box-border text-4xl font-medium text-center text-primaryA;
}

.subtitle {
  @apply mt-3.5 mb-6 box-border font-inter text-primaryA text-sm text-center;
}
.subtitle strong {
  @apply font-normal;
}

.filters {
  @apply flex flex-col lg:flex-row justify-between py-5;
}

.filterContainer {
  @apply order-1 flex items-center w-full lg:w-1/2 mt-2.5 lg:mt-0 relative bg-primaryB border border-grey300;
}
.filterBar {
  @apply order-2 lg:order-1;
  @screen lg {
    width: 15.625rem;
  }
  @apply border-none;
}

.searchBar,
.selectField {
  @apply w-full py-4 pl-5 pr-11 font-inter border-none text-base lg:text-sm font-normal bg-primaryB;
}
.searchBar {
  @apply pr-5 pl-11;
}
.searchBar::placeholder {
  @apply text-primaryA;
}

.icon {
  @apply absolute left-3;
}

.arrowDown {
  @apply absolute right-3 text-primaryA;
  transform: rotate(90deg);
}

.listContainer {
  @apply order-3 lg:order-1 flex flex-row justify-center items-center w-full lg:w-auto relative cursor-pointer;
}
.listContent {
  @apply flex items-center flex-1 mt-2.5 lg:mt-0 p-3.5 relative bg-primaryB border border-grey300;
  min-width: 7.5rem;
}
.listContent:first-child {
  @apply mr-2.5;
}
.listContent.enable {
  @apply border-primaryA;
}
.listCta {
  @apply ml-3 text-sm;
}

[dir='rtl'] .listContent:first-child {
  @apply ml-2.5 mr-0;
}
[dir='rtl'] .listCta {
  @apply mr-3 ml-0;
}
