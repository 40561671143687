.root {
  @apply relative;
  overflow-y: hidden;
}

.control {
  @apply absolute top-1/2 w-12 h-full flex items-center justify-center;
  z-index: 1;
  transform: translateY(-50%);
}

.leftControl:before,
.rightControl:before {
  @apply w-5 h-5 border-b border-r border-primaryA;
  content: '';
}

.leftControl:before {
  transform: translateX(20%) rotate(135deg);
}

.rightControl:before {
  transform: translateX(-20%) rotate(315deg);
}

.leftControl {
  @apply left-0;
}

.rightControl {
  @apply right-0;
}

.figureImg {
  @apply flex;
}

.img {
  @apply object-cover w-full;
}

.img.mobile {
  width: calc(100vw - 0.625rem);
  height: calc(100vw - 0.625rem);
}

.img:hover {
  @screen md {
    @apply opacity-0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.7s;

    cursor: url('/img/loupe-2.svg') 0 0, zoom-in;
    cursor: -webkit-image-set(
          url('/img/loupe-2.svg') 1x,
          url('/img/loupe-2-2x.svg') 2x
        )
        0 0,
      zoom-in;
  }
}

.positionIndicatorsContainer {
  @apply flex items-center justify-center;
  @screen sm {
    @apply hidden;
  }
}

.positionIndicator {
  margin: 0 0.3125rem;
  height: 1.5625rem;
}

.dot {
  @apply transition rounded-full bg-primaryA;
  width: 0.3125rem;
  height: 0.3125rem;
}

.positionIndicatorActive .dot {
  @apply border border-primaryA bg-primaryB;
}
