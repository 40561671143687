.root {
  @apply flex  justify-center  items-center h-10 lg:h-12 py-4 px-10 text-primaryB leading-6 text-sm font-medium uppercase shadow-sm  border border-transparent cursor-pointer;
  background: var(--primaryA);
  transition: background 0.3s;
}

.rootWithIcon {
  @apply px-3;
}

.root:hover {
  @apply border border-primaryA;
  @screen md {
    background: #343333;
  }
}

.root[data-active] {
  @apply bg-gray-600;
}

.icon {
  @apply mr-1.5 lg:mr-2 w-4 h-4 flex items-center justify-center;
}
[dir='rtl'] .icon {
  @apply ml-1.5 mr-0 lg:ml-2 lg:mr-0;
}

.rootLoading {
  @apply flex-col;
}

.placeholder {
  @apply h-0 overflow-hidden invisible;
}
