.root {
  @apply flex flex-col  justify-start items-center lg:items-center !important;
}

.logo {
  @apply w-56 lg:py-0.5 mr-4;
}
[dir='rtl'] .logo {
  @apply ml-4 mr-0;
}

.socialLinks {
  @apply mt-12 lg:mt-6;
}
