.container {
  @apply flex flex-col items-center w-full box-border relative block-cms-container-mobile;

  @screen md {
    @apply w-1/2 block-cms-container-tablet;
    height: calc(31vw - 1.375rem) !important;
  }

  @screen lg {
    height: calc(31vw - 1rem) !important;
  }
  @screen xl {
    @apply w-1/2 block-cms-container-desktop;
    height: calc(31vw - 2.625rem) !important;
  }
}

.media {
  @apply block w-full h-auto mb-4 overflow-hidden;
}
.media img {
  @apply block w-full;
  transition: transform 1s;
  transform: scale(1);
}

@screen xl {
  .description {
    padding: 0 20%;
  }
}

@media screen and (min-width: 1510px) {
  .container {
    max-height: 43.75rem;
  }
}
