.container {
  @apply w-full my-0 box-border relative;
  @screen md {
    @apply mt-16 px-4;
  }
  @screen xl {
    @apply px-11;
  }
}

.content {
  @apply flex flex-col justify-center items-center w-full relative border-t lg:border-primaryA;
}

.title {
  @apply mb-6 pt-6 px-4 font-gilda text-2xl text-primaryA bg-primaryB;
  @screen lg {
    @apply py-0 transform -translate-y-1/2;
  }
}

.row {
  @apply flex flex-col lg:flex-row justify-start items-center w-full relative;
}
.col {
  @apply w-full;
  @screen lg {
    @apply w-1/3;
  }
}
.col + div {
  @apply pb-7;
}
