.container {
  @apply flex lg:hidden justify-center items-center w-full fixed -top-full left-0 z-40;
  background-color: rgba(0, 0, 0, 0.9);
  transition: top 0.7s linear;
}
.container.enable {
  @apply top-0;
}

.content {
  @apply flex flex-row justify-around items-center w-full;
}

.content p {
  @apply flex-1 flex flex-col items-center font-inter  text-sm text-primaryB text-center;
  padding: 0.4375rem;
}

.link {
  @apply flex-1 flex flex-col items-center px-2 font-medium text-sm bg-primaryB text-primaryA border border-primaryA;
  height: 3rem;
  margin: 0.75rem 0.4375rem;
  @screen md {
    margin: 0.875rem 0.4375rem;
  }
}
