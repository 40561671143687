.summary {
  @apply flex flex-row justify-between items-center w-full h-auto relative mb-2.5;
}
.evaluation,
.symbol {
  @apply flex flex-col justify-center;
}
.evaluation {
  @apply items-start;
}
.symbol {
  @apply items-end;
}

.total {
  @apply text-sm font-medium;
}
.average {
  @apply mt-2.5 text-xl;
}
.scale {
  @apply text-grey400;
}
.stars {
  @apply flex flex-row mt-4;
}
.stars svg {
  @apply ml-0.5;
}

[dir='rtl'] .stars svg {
  @apply mr-0.5 ml-0;
}
