.container {
  @apply flex flex-col items-center w-full box-border relative block-cms-container-mobile;
  @screen md {
    @apply block-cms-container-desktop px-0;
  }
}

.media {
  @apply w-full h-full relative bg-cover bg-center bg-no-repeat cursor-pointer;
  height: calc(100vh - 11.25rem);
  @screen xl {
    height: calc(100vh - 11.75rem);
    min-height: 15rem;
    max-height: 900px;
  }
}

.layout {
  @apply w-full h-full absolute top-0 left-0 bg-primaryA;
  opacity: 0.15;
}

.description {
  @apply w-auto max-w-full absolute flex flex-col items-start ml-4 px-2 pb-0 pt-2.5 text-primaryB text-center md:max-w-2xl;

  @screen sm {
    @apply ml-0 px-12 py-10 opacity-0;
    animation: fadeIn 0.5s linear 0.3s;
    animation-fill-mode: forwards;
  }
}

[dir='rtl'] .description {
  @apply mr-4 ml-0;
  @screen sm {
    @apply mr-0;
  }
}

@screen md {
  .layoutLeft .description {
    @apply bottom-0 left-0 md:text-left;
  }

  .layoutCenter .description {
    @apply top-1/2 left-1/2 p-0 text-center my-0;
    transform: translate(-50%, -50%);
  }
}

.container h1,
.container p {
  text-shadow: 0.2px 0.2px 0 rgb(0 0 0 / 70%) !important;
}

.layoutLeft h1,
.layoutLeft p {
  @apply text-left !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
