.item + .item {
  @apply border-t border-solid;
  border-color: var(--grey200);
}

.buttonContainer {
  @apply relative;
}

.link {
  @apply relative my-1 flex flex-wrap justify-between items-center text-primaryA text-center;
}

.button {
  @apply absolute top-0 w-full h-full cursor-pointer;
}

.title {
  @apply my-3 py-1 w-full font-medium;
  font-size: 0.8125rem;
}

.arrow {
  @apply absolute;
  right: 0.625rem;
  transition: transform 0.15s cubic-bezier(0.75, 0.02, 0.5, 1);
}

.expanded .arrow {
  transform: rotate(90deg);
}

.items {
  @apply block max-h-0 h-auto p-0 overflow-hidden opacity-0;
  transition: all 0.3s cubic-bezier(0.75, 0.02, 0.5, 1) 0.1s,
    opacity 0.15s cubic-bezier(0.75, 0.02, 0.5, 1) 0.3s;

  @screen xl {
    @apply transition-none opacity-100;
    padding: 0.9375rem 0 0.625rem;
    max-height: inherit;
  }
}

.expanded .items {
  @apply opacity-100 pb-5;
  max-height: 100rem;

  @screen xl {
    @apply pb-0;
    max-height: inherit;
  }
}

.subItem {
  @apply text-center;
  height: 1.875rem;
  color: var(--grey600);
}
