.container {
  @apply hidden;
  @apply xl:flex items-center justify-center;
  height: 1.875rem;
}

.background {
  @apply absolute top-0 left-0 right-0;
  height: 1.875rem;
  background-color: #b15a3b;
}

.text {
  @apply text-primaryB font-medium uppercase m-0;
  font-size: 0.625rem;
}

.redirection {
  @apply uppercase underline cursor-pointer text-white hover:text-white;
}

.noFadeAnimation {
  @apply absolute;
}

.fade {
  @apply absolute;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-name: fadeAnimation;
  animation-direction: normal;
}

.hide {
  @apply hidden;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
