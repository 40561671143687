.container {
  @apply flex flex-col items-center w-full box-border relative bg-cover bg-center block-cms-container-mobile;
  @screen md {
    @apply block-cms-container-tablet;
  }
  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.description {
  @apply w-full relative py-4 bg-transparent;

  @screen md {
    @apply pt-6 pb-4 px-4;
    max-width: 55%;
    margin: 0 5%;
  }
}
