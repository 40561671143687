.container {
  @apply w-full block-cms-container-mobile;
  @screen md {
    @apply block-cms-container-tablet;
  }
  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.separator {
  @apply w-full px-2.5 md:px-5  bg-primaryA;
}
.small {
  @apply h-px;
}

.large {
  @apply h-2.5;
}
