.customSelectContainer {
  @apply font-inter w-full;
  color: var(--primaryA);
  font-size: 0.75rem;
}

.placeholderContainer {
  @apply flex justify-between;
}

.customSelectContainer .textSelected {
  @apply flex items-center;
}

.singleColorContainer {
  @apply h-12 flex items-center justify-between border border-grey300 box-border p-4;
}

.singleColor,
.textSelected,
.countColors {
  @apply font-inter text-sm text-primaryA;
}
.countColors {
  color: var(--grey600);
}

.label {
  @apply font-inter text-sm  ml-2;
}

[dir='rtl'] .label {
  @apply mr-2 ml-0;
}
