.dropdown {
  @apply fixed bottom-0 right-0  w-full bg-primaryB cursor-default z-50 opacity-0;
  animation-name: slideInUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}
.dropdownAlt {
  bottom: -100%;
  animation-name: slideInUpAlt;
}
.backdrop {
  @apply fixed z-40 inset-0 opacity-0;
  background-color: var(--backdrop);
  animation: fadeIn 0.25s linear;
  animation-fill-mode: forwards;
}

.head {
  @apply flex items-center justify-center w-full h-auto relative bg-primaryB border-b border-grey300;
}
.title {
  @apply relative p-4 font-inter text-base font-medium;
}
.close {
  @apply h-5 w-5 absolute right-2.5 p-3 box-content cursor-pointer;
}

.sizeContainer {
  @apply w-full h-auto relative;
  max-height: 80vh;
  overflow-y: auto;
}
.sizeContent {
  @apply flex flex-col items-center justify-center w-full h-auto relative pt-2.5 pb-8 px-2.5 bg-grey50;
}

.sizeValue {
  @apply flex flex-row justify-between  items-center w-full h-auto relative my-1 text-sm text-primaryA  bg-primaryB border border-grey300 cursor-pointer;
  padding: 0.3125rem 0.625rem;
}

.inStock:active {
  @apply text-primaryB bg-primaryA;
}

.lastChance {
  @apply text-lowstock;
  border-color: #e3cfbd;
}
.lastChance:active {
  @apply text-primaryB bg-lowstock;
}

.noStock {
  @apply text-grey300 border-grey200;
}
.noStock:active {
  @apply text-primaryB bg-grey300;
}

.details {
  @apply flex flex-col justify-center items-end;
}
.initialPrice {
  text-decoration-line: line-through;
}
.inStock .initialPrice {
  @apply text-grey600;
}
.inStock:active .initialPrice {
  @apply text-primaryB;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideInUp {
  from {
    opacity: 1;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideInUpAlt {
  from {
    opacity: 1;
    bottom: -100%;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}
