.container {
  @apply flex flex-row items-center w-full sticky mb-5 bg-primaryB border-t border-b border-grey300;
  top: 3.5rem;
  z-index: 35;
  @screen md {
    top: 4rem;
  }
}

.button {
  @apply w-full relative uppercase font-medium text-center py-4 px-1 sm:px-2.5 text-primaryA text-sm;
}

.separator {
  @apply h-6 bg-grey300;
  width: 0.0625rem;
}

.cta {
  @apply relative mx-3;
}
.cta:after {
  @apply block w-full h-0 absolute mt-1 border-b-4 border-primaryA;
  content: '';
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.selected:after {
  transform: scaleX(1);
}
