.container {
  @apply w-full h-full relative opacity-100;
  padding: 0.625rem 0 0rem;
}
.hidden {
  @apply opacity-50;
}
.title {
  @apply w-full relative p-0 text-grey600;
  font-size: 1rem;
  line-height: 1.125rem;
}
.title_link {
  @apply font-medium uppercase;
  font-size: 0.6875rem;
}
.title_link:hover {
  @apply text-primaryA;
}

.controller {
  @apply relative text-center;
  height: 1.3125rem;
}
.controller > div {
  @apply cursor-pointer;
}
.controller.arrow_up {
  border-bottom: 0.0625rem solid var(--grey300);
}
.controller.arrow_up > div {
  @apply mt-4;
}
.controller.arrow_down {
  border-top: 0.0625rem solid var(--grey300);
}
.arrow {
  @apply inline-block align-middle relative;
  border-left: 0.3125rem solid #9a9a9a;
  border-top: 0.3125rem solid transparent;
  border-bottom: 0.3125rem solid transparent;
}
.arrow.arrow_up {
  transform: rotate(270deg);
}
.arrow.arrow_down {
  transform: rotate(90deg);
}
.arrow:after {
  content: '';
  position: absolute;
  top: -0.3125rem;
  right: 0.125rem;
  border-left: 0.3125rem solid #fff;
  border-top: 0.3125rem solid transparent;
  border-bottom: 0.3125rem solid transparent;
}

.total {
  @apply flex flex-col items-center uppercase;
  font-size: 0.875rem;
  line-height: 1.75rem;
}
.total small {
  font-size: 0.75rem;
}

.order {
  @apply flex flex-col items-center relative;
  margin: 0.625rem 0 0rem;
}

.cta {
  min-width: 13.75rem;
  padding: 0.9375rem 1.125rem;
}

.paypalDisclaimer {
  @apply mt-2.5;
  padding-left: 0.7rem;
}
