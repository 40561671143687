.container {
  @apply flex flex-col items-center text-center justify-center relative px-4 pt-14 pb-10 md:pt-24 md:pb-14;
}

.title {
  @apply font-gilda  justify-center font-normal;
  margin-bottom: 0.25rem;
  font-size: 2rem;
  line-height: 2.25rem;
}

.subTitle {
  @apply mb-10 font-lora text-primaryA;
  font-size: 1rem;
}

.link {
  @apply bg-primaryA text-primaryB uppercase border border-solid border-primaryA font-medium py-4 w-full;
  font-size: 0.75rem;
  @screen sm {
    @apply px-24;
    width: 28rem;
    transition: 0.3s;
  }
}

.link:hover {
  @apply border border-primaryA;
  @screen md {
    background-color: #343333;
  }
}
