.container {
  @apply flex flex-col justify-center max-w-full relative;
  width: 45rem;
  height: calc(100vh - 2.5rem);
  min-height: 18.75rem;

  @screen md {
    @apply block h-auto;
  }
}
