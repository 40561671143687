.videoBackground {
  @apply fixed inset-0 z-40;
  background-color: rgba(0, 0, 0, 0.4);
}

.videoContainer {
  @apply fixed top-8 left-1/2 bg-primaryB overflow-y-auto z-50;
  transform: translate(-50%, 0%);
  width: 90vw;
  max-height: 85vh;
  @screen md {
    @apply top-1/2;
    width: 50vw;
    transform: translate(-50%, -50%);
  }
}

.desc {
  @apply w-full relative p-6 pb-4 text-base text-primaryA bg-grey50;
}
.inner {
  @apply w-full relative mx-auto px-6;
  @screen lg {
    max-width: 37.5rem;
  }
}
.date {
  @apply text-sm text-grey400;
}
.type {
  @apply pb-1.5 text-lg font-medium;
}
.title {
  @apply text-base;
}
.text {
  @apply w-full relative py-6 px-6 mx-auto text-base leading-6;
  @screen lg {
    max-width: 37.5rem;
  }
}
