.container {
  @apply flex flex-row justify-start items-center relative w-full py-5 border-b border-grey300;
}

.content {
  @apply flex flex-row justify-start items-center w-full relative px-4 md:px-0;
}
.content li,
.content p {
  @apply pb-1 font-inter text-sm;
}

.media {
  width: 5.625rem;
}

.desc {
  @apply flex-1 pl-4;
}

.brand {
  @apply text-sm uppercase;
}

.quantityHandler {
  @apply w-full font-inter text-sm;
}

.quantity {
  @apply inline-flex flex-row justify-start items-center w-auto pb-0 border border-grey600;
}

.quantityCta {
  @apply text-primaryA text-center font-gilda font-normal uppercase;
  font-size: 2.5rem;
  line-height: 2.5rem;
}
.quantityCta.more {
  padding: 0.75rem 0.625rem 0.75rem 1.25rem;
}
.quantityCta.less {
  padding: 0.75rem 1.25rem 0.75rem 0.625rem;
}

.quantityRemove {
  @apply hidden xs:block;
  font-size: 0.75rem;
  line-height: 0.75rem;
}
.quantityRemove span {
  @apply pl-1 text-base;
}

.row {
  @apply flex flex-row justify-between items-center;
}

.price {
  @apply font-medium;
  @apply pb-0 !important;
}
