.root {
  @apply mt-2.5 border-t-2 border-solid border-primaryA pt-2.5 xl:hidden;
}

.item {
  @apply border-t border-b border-solid;

  border-color: var(--grey200);
}

.item + .item {
  @apply border-t-0;
}

.buttonContainer {
  @apply flex items-center justify-center mx-0;
  height: 3.6875rem;
}

.buttonContainer > span {
  @apply text-sm font-medium text-primaryB bg-primaryA border-0 py-1 px-2.5 rounded-full;
}
