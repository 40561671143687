.comments {
  @apply relative my-2.5 pt-3 text-sm border-t border-grey300;
}
.row {
  @apply flex flex-row justify-between items-center;
}
.name {
  @apply font-medium;
}
.evaluated {
  @apply mt-2.5 text-grey600;
}
.content {
  @apply mt-4 italic;
}
.stars {
  @apply flex flex-row mt-1;
}
.stars svg {
  @apply ml-0.5;
}

[dir='rtl'] .stars svg {
  @apply mr-0.5 ml-0;
}
