.bd_slider {
  @apply flex flex-row items-center justify-center;

  @screen md {
    @apply bg-transparent flex-col;
  }
}

.bd_list {
  @apply h-auto flex;
  padding: 0.3125rem;

  @screen md {
    @apply flex flex-col p-0;
  }
}

.bd_list_grid {
  @apply grid-cols-8 md:flex;
  display: grid;

  @screen md {
    @apply flex;
  }
}

.imgContainer {
  @apply flex border border-transparent;
  padding: 0.3125rem;
}

.imgContainerModal {
  @apply flex border border-transparent w-10;
  margin: 0.3125rem;

  @screen md {
    @apply w-auto m-0;
  }
}

.imgContainer:hover {
  @apply cursor-default;
}

.selectedImg {
  @apply border;
  border-color: rgba(0, 0, 0, 0.2);
}

.selectedImgModal {
  @apply border border-primaryA p-0;
}

.img {
  @apply object-cover;
}

.bd_slider_controller {
  @apply hidden;
  @screen md {
    @apply block text-center relative;
    height: 1.3125rem;
    user-select: none;
  }
}

.bd_slider_arrow {
  @apply w-full h-full flex items-center justify-center;
}

.arrow_previous:before,
.arrow_next:before {
  @apply border-b border-r;
  width: 0.625rem;
  height: 0.625rem;
  border-color: var(--grey400);
  content: '';
}

.arrow_previous:before {
  @screen md {
    transform: translateY(-30%) rotate(45deg);
  }
}

.arrow_next:before {
  @screen md {
    transform: translateY(30%) rotate(-135deg);
  }
}
