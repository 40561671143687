.container {
  @apply w-full relative block-cms-container-mobile p-0 overflow-x-hidden;
  margin: 0.5rem auto 1.25rem;
  @screen md {
    margin: 1rem auto 1.875rem;
  }
}

.head {
  @apply w-full flex flex-row md:flex-row-reverse justify-between items-center relative px-2 lg:px-0.5 pb-2;
}

.title {
  @apply xl:px-2 font-gilda text-4xl font-normal;
  font-size: 2rem;
  @screen md {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}

.control {
  @apply flex justify-center w-auto h-7 relative pr-2;
}
.arrowLeft,
.arrowRight {
  @apply w-12 md:w-6 h-full;
}
.arrowLeft::before,
.arrowRight::before {
  @apply w-3.5 h-3.5 absolute border-t border-l border-solid border-primaryB;
  content: '';
  box-shadow: -0.0625rem -0.0625rem 0 black;
  @screen md {
    margin: auto -0.3125rem 0;
  }
}
.arrowLeft::before {
  transform: translateY(-50%) rotate(-45deg);
}
.arrowRight::before {
  transform: translateY(-50%) rotate(135deg);
}

.slider {
  @apply px-0.5;
}

.slide {
  @apply md:px-0.5 py-2 box-border;
}
.slide.floating {
  @apply pr-0.5;
}

.link {
  @apply items-start;
}
@screen md {
  .link .mediaContainer {
    transition: transform 0.5s;
  }
  .link:hover .mediaContainer {
    transform: scale(1.01) translateY(-1%);
  }
}

.mediaContainer {
  @apply h-60 w-full mb-5 bg-cover bg-center;
}
@screen md {
  .mediaContainer.large {
    height: 23.25rem;
  }
}
.name {
  @apply mb-2 px-2 font-inter font-normal text-xl uppercase;
  font-size: 1.375rem;
}
.desc {
  @apply max-w-xl px-2 text-sm;
}
.cta {
  @apply mt-1.5 mb-2 md:mb-0 px-2 text-sm font-medium underline;
}

.jsDisabled {
  @apply flex flex-col;
}
.jsDisabledBlocks {
  @apply pb-3;
}
