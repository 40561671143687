.container {
  @apply w-full box-border relative;
  @screen md {
    @apply mt-9 mb-0 px-4;
  }
  @screen xl {
    @apply px-11;
  }
}

.content {
  @apply flex flex-col justify-center items-center w-full relative border-t lg:border-primaryA;
}

.title {
  @apply mb-6  py-0 px-4 font-gilda text-2xl text-primaryA bg-primaryB transform -translate-y-1/2;
}

.inspiration {
  @apply flex;
  align-items: stretch;
}

.leftContainer {
  @apply w-1/2 mr-2;
}
[dir='rtl'] .leftContainer {
  @apply ml-2 mr-0;
}

.inspirationImage {
  @apply block w-full h-full;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rightContainer {
  @apply w-1/2 ml-2;
}

[dir='rtl'] .rightContainer {
  @apply mr-2 ml-0;
}

.rightContainer.full {
  @apply w-full;
}

.rightContainer ul {
  @apply flex flex-row flex-wrap;
}

.rightContainer ul li {
  @apply w-1/3 box-border p-1;
}
.rightContainer.full ul li {
  @apply w-1/3 md:w-1/6;
}

.link {
  @apply block w-full h-full;
}
