.root {
  @apply flex md:mr-3;
}
[dir='rtl'] .root {
  @apply md:ml-3 md:mr-0;
}

.searchBarMobileIcon {
  @apply flex items-center;
  height: 1.5rem;
}
