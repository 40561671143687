.container {
  @apply flex flex-row justify-between w-full relative top-0 mb-5 border-gray-300 bg-primaryB;
  font-size: 0.875rem;
  z-index: 18;

  @screen md {
    @apply mb-0;
    font-size: 0.75rem;
  }
  @screen lg {
    @apply sticky m-0 py-3;
    top: 4rem;
  }
  @screen xl {
    @apply top-0;
  }
}
.layoutBottom.container {
  @apply flex border-t mt-5 mb-0 py-4;
}

.total {
  @apply text-sm;
}
.toggleFilters {
  @apply flex flex-row items-start text-sm;
  min-width: 7.8125rem;
}
.colFilters {
  @apply hidden lg:flex flex-row items-start;
  padding: 0.5625rem 0;
  @screen lg {
    width: 15.5rem;
  }
  @screen xl {
    width: 20rem;
  }
}
.colSort {
  @apply hidden lg:flex flex-row items-start my-0.5;
}

.icon {
  @apply mr-2;
}

.separator {
  @apply px-2;
}

.selectorContainer {
  @apply flex flex-wrap items-center relative;
}
.selectorContainer::after {
  @apply absolute right-4 bottom-4;
  content: '';
  width: 0.313rem;
  height: 0.313rem;
  transform: rotate(45deg);
  box-shadow: 0.0625rem 0.0625rem 0 var(--primaryA);
}
.selectorContainer + .selectorContainer {
  @apply ml-5;
}

[dir='rtl'] .selectorContainer + .selectorContainer {
  @apply mr-5 ml-0;
}

.selector {
  @apply w-11/12 h-full absolute top-0 left-1/2 transform -translate-x-2/4 opacity-0;
  @screen lg {
    @apply w-full relative top-auto left-auto py-1.5 pl-2 opacity-100 text-grey600 text-sm border border-grey300 bg-primaryB cursor-pointer transform-none -translate-x-0;
    padding-right: 1.575rem;
  }
}

.selector.visible {
  @apply opacity-100;
}
