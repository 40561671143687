.container {
  @apply w-full box-border relative;
  @screen md {
    @apply mt-16 mb-0 px-4;
  }
  @screen xl {
    @apply px-11;
  }
}

.content {
  @apply flex flex-col justify-center items-center w-full relative border-t lg:border-primaryA;
}

.title {
  @apply mb-6 pt-6 px-4 font-gilda text-2xl text-primaryA bg-primaryB;
  @screen lg {
    @apply py-0 transform -translate-y-1/2;
  }
}

.designer {
  @apply flex items-center w-full;
}

.mediaContainer {
  @apply flex justify-center w-1/2;
}

.mediaContainer img {
  @apply max-h-72;
}

.descriptionContainer {
  @apply w-full lg:w-1/2 flex flex-col items-center mb-6;
  padding: 0 5%;
  @screen lg {
    @apply m-auto;
  }
}

.descriptionContainer h1 {
  @apply typo-gilda-title text-center;
}

.description {
  @apply font-lora text-xs text-primaryA text-center mt-1 flex flex-col items-center;
}
