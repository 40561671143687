.priceLineContainer {
  @apply flex flex-col;
}

.priceLine {
  @apply flex flex-row items-center font-inter text-grey600 text-sm;
}
.priceLine > * + * {
  @apply ml-2.5;
}

[dir='rtl'] .priceLine > * + * {
  @apply mr-2.5 ml-0;
}

.price {
  @apply text-primaryA;
}
.basePrice {
  @apply line-through;
}
.discount {
  @apply h-5 flex items-center justify-center bg-primaryA text-primaryB font-medium px-1;
  font-size: 0.75rem;
  line-height: 1rem;
}

.labelFrom {
  @apply font-normal text-sm;
}
