.center {
  @apply flex justify-center;
}

.container {
  @apply xl:border-t xl:border-b xl:border-grey300;
  margin: 0 !important;
}

.full_width {
  @apply w-full;
}

.results {
  @apply w-full;
}

.no_results {
  @apply py-5 typo-inter-text;
  @screen xl {
    @apply max-w-md pt-0 pb-6 mx-auto text-center;
  }
}
.no_results.listing {
  @apply px-2.5 text-center;
  @screen xl {
    @apply pt-7 pb-0 xl:px-0;
  }
}

.oups {
  @apply hidden;

  @screen xl {
    @apply block p-1 typo-gilda-title;
  }
}

.noResultFor {
  @apply block p-0 text-sm typo-inter-text text-primaryA;
  line-height: 1.375rem;
}

.query {
  @apply hidden;

  @screen xl {
    @apply block text-sm typo-inter-text text-primaryA;
    line-height: 1.375rem;
  }
}

.notToWorry {
  @apply hidden;

  @screen xl {
    @apply block text-sm typo-inter-text text-grey600;
    line-height: 1.375rem;
  }
}

.seeMore {
  @apply flex items-center justify-center w-full relative bottom-0 left-0 p-3 typo-inter-text font-normal text-primaryA uppercase bg-primaryB;

  @screen xl {
    @apply w-auto justify-center px-6 pt-0 pb-4 mt-4 bg-transparent;
    align-self: flex-end;
  }
}

.btnSeeMore {
  @apply w-full content-center h-12 text-sm font-medium text-center uppercase bg-primaryA text-primaryB align-middle cursor-pointer;

  @screen xl {
    @apply px-12;
  }
}
