.container {
  @apply flex flex-col items-center w-full box-border relative mb-5;

  @screen md {
    @apply inline-block w-1/2 px-0.5;
    max-width: 33.3%;
    .description > p {
      @apply overflow-hidden whitespace-nowrap overflow-ellipsis;
    }
  }
}
.container.col_2 {
  @screen md {
    @apply inline-block;
    max-width: 50%;
  }
}
.container.col_4 {
  @screen md {
    @apply inline-block;
    max-width: 25%;
  }
}

.media {
  @apply block w-full;
}
.media img {
  @apply block w-full;
  transition: transform 1s;
  transform: scale(1);
}

.description {
  @apply w-full relative mt-3 mb-0 pt-2.5 pb-0 md:px-4 bg-primaryB;
}
