.container {
  @apply flex flex-row justify-start items-end relative;
  min-height: 8.125rem;
  padding: 1.125rem 0;
}
.container:not(:first-child) {
  border-top: 0.0625rem solid var(--grey300);
}
.content {
  @apply flex-1 flex flex-row justify-start items-center;
}
.desc {
  @apply flex-1 relative;
  padding-left: 0.625rem;
}
.brand {
  @apply text-xs uppercase;
  padding-bottom: 0.1875rem;
  line-height: 0.9375rem;
}
.attr li {
  @apply font-lora text-xs whitespace-nowrap;
  line-height: 0.9375rem;
}
.attr li:last-child {
  @apply mt-1;
}
.title {
  @apply truncate;
  max-width: 11.875rem;
}
.remove {
  color: #9e9e9e;
  font-size: 1.125rem;
  font-weight: 400;
}
