.container {
  @apply flex flex-col items-center w-full box-border relative;
}

.mediaContainer {
  @apply w-full h-96 relative mb-5 md:mb-7;
  @screen md {
    height: 65vh;
    min-height: 25rem;
    max-height: 900px;
  }
}
.media {
  @apply w-full h-full relative bg-cover bg-center bg-no-repeat;
}
.layout {
  @apply w-full h-full absolute top-0 left-0 bg-primaryA;
  opacity: 0.1;
}

.section {
  @apply typo-inter-text text-center uppercase md:pb-2.5;
}

.desc {
  @apply px-2.5 md:px-0;
}

.cta {
  @apply h-auto mx-auto mb-5 p-2.5 typo-inter-entitled text-primaryA text-center bg-primaryB border-2 border-primaryA;
  width: 15.25rem;
  transition: background-color 0.3s;
}

.cta:hover {
  @apply text-primaryB bg-primaryA;
}
