.formContainer {
  @apply w-full relative mb-2.5;
  @screen md {
    @apply mb-3;
  }
}

.formContent {
  @apply w-full;
  margin-top: 0.625rem;
}

.title {
  @apply text-base font-medium;
}

.info {
  @apply w-full  text-sm font-normal;
  line-height: 1.375rem;
}

.button {
  @apply w-full text-sm text-primaryA pb-2.5 underline whitespace-nowrap mt-4;

  @screen md {
    margin-top: 0.3125rem;
  }
}

.formAction {
  @apply w-full flex flex-col items-center  mt-2.5;

  @screen md {
    @apply flex-col-reverse items-start justify-center;
    margin: 0.3125rem 0 1.25rem;
  }
}

.formAction > button {
  @apply w-full whitespace-nowrap  h-12;
  @screen md {
    @apply text-sm;
  }
}

.errorInput {
  @apply border border-solid border-negative;
}

.formComment {
  @apply flex underline;
}
.alertMessage {
  @apply h-auto bg-red50 p-2.5 mb-5;
}

.fadeIn {
  opacity: 0.3;
  transition: opacity 0.3s;
}
