.root {
  @apply block relative w-full overflow-hidden mt-5 mb-2;
  max-width: 21.875rem;
  height: 27.5rem;
}

.legend {
  @apply absolute bottom-5 ml-5;
}

[dir='rtl'] .legend {
  @apply mr-5 ml-0;
}

.title {
  @apply text-4xl text-primaryB font-gilda leading-none;
}

.subtitle {
  @apply mt-1.5 text-primaryB text-xs underline;
}
