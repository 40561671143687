.container {
  @apply flex flex-row items-center w-full sticky mb-5 bg-primaryB border-t border-b border-grey300;
  top: 3.5rem;
  z-index: 35;

  @screen md {
    top: 4rem;
  }
}
.container.headerTop {
  top: 2.375rem;
  @screen xl {
    top: 4.1875rem;
  }
}

.button {
  @apply w-full relative uppercase font-medium text-center py-4 px-1 sm:px-2.5 text-primaryA text-sm;
}

.separator {
  @apply h-6 bg-grey300;
  width: 0.0625rem;
}

.selector {
  @apply w-11/12 h-full absolute top-0 left-1/2 transform -translate-x-2/4 opacity-0 z-30;
}
.selector.visible {
  @apply opacity-100;
}

.cta {
  @apply relative mx-3;
}
.cta:after {
  @apply block w-full h-0 absolute mt-1 border-b-4 border-primaryA;
  content: '';
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.selected:after {
  transform: scaleX(1);
}
