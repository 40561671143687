.container {
  @apply w-full h-auto relative  pt-0 pb-3 px-0 cursor-pointer;
  @screen lg {
    padding: 0 0.75rem 0.75rem;
  }
}
.container.half {
  padding: 0 0rem 0.75rem;
}

.unavailable {
  @apply flex flex-col justify-center items-center w-full h-full absolute top-0 left-0 text-sm bg-primaryB opacity-80 cursor-not-allowed z-20;
}

.content {
  @apply flex flex-col justify-start items-start w-full relative p-0;
}

.img {
  @apply w-full relative;
}

.half .slide {
  @apply pt-5;
}

.slide > div:nth-child(1) {
  @apply w-full relative;
  z-index: 9;
  transition: opacity 0.2s;
  transition-delay: 0.4s;
}
.slide > div:nth-child(n + 2) {
  @apply w-full h-auto top-0 left-1/2	opacity-0 z-10;
  position: absolute !important;
  transform: translateX(-50%);
  transition: opacity 0.2s;
  transition-delay: 0.4s;
}
.hoverEffect:hover > div:nth-child(1) {
  @apply opacity-0;
}
.hoverEffect:hover > div:nth-child(n + 2) {
  @apply opacity-100;
}

.desc {
  @apply w-full md:mb-2 px-2.5 lg:px-3;
  transition: transform 0.3s;
}

.slidedown {
  transform: translateY(50px);
}

.attr {
  @apply flex flex-col items-start;
}

.brand {
  @apply py-1 text-base text-primaryA uppercase;
}
.brand strong {
  @apply font-medium;
}
.title {
  @apply max-w-full text-primaryA text-sm overflow-hidden overflow-ellipsis whitespace-nowrap;
}
.prices {
  @apply flex flex-col xl:flex-row justify-between w-full mt-3.5 text-base font-medium text-primaryA leading-4;
}
.siblings {
  @apply pt-2 lg:pt-0 text-sm text-grey500;
}
.half .prices {
  @apply h-4 mt-3.5;
}
.half .siblings {
  @apply px-2.5 text-sm text-primaryA;
}
.onDemand {
  @apply text-grey500 text-sm;
}
.half .onDemand {
  @apply w-full  absolute pt-1 px-2.5 overflow-hidden overflow-ellipsis whitespace-nowrap;
}

.addToGiftList,
.addToWishList {
  @apply block absolute right-5 p-3 rounded-full bg-primaryB;
  transform: translate(0.625rem, -1rem);
  box-shadow: 0 0.0625rem 0.25rem rgba(18, 18, 18, 0.1);
  z-index: 15;
  transition: all 0.3s;

  @screen lg {
    transform: translateY(-0.625rem);
  }
}

[dir='rtl'] .addToGiftList,
[dir='rtl'] .addToWishList {
  @apply left-5;
  right: unset;
}
.addToGiftList:hover,
.addToWishList:hover {
  @apply bg-grey50;
}
.addToGiftList {
  top: 0.4375rem;
}
.addToWishList {
  top: 3.625rem;
}
@screen lg {
  .addToGiftList {
    @apply top-0;
  }
  .addToWishList {
    @apply top-12;
  }
}
.half .addToWishList {
  @apply top-0;
}
.addToWishList.enabled {
  @apply z-20;
}

.listIcon {
  @apply cursor-pointer;
}

.tagLine {
  @apply flex justify-between items-center w-full my-2 px-2.5 md:px-0;
}

[dir='rtl'] .tagLine {
  @apply flex-row-reverse;
}

.half .tagLine {
  @apply h-6 mb-3;
}

.labelFrom {
  @apply font-normal;
}

.initialPrice {
  @apply mx-1 line-through text-grey600 font-normal;
  font-size: 0.875rem;
}
.salePrice {
  @apply text-promotion;
}
.discountAmount {
  @apply font-inter text-sm font-medium ml-1 normal-case bg-primaryA text-primaryB;
  border-radius: 0.1875rem;
  padding: 0.3125rem 0.46875rem;
  transform: translateY(-0.15625rem);
}

[dir='rtl'] .discountAmount {
  @apply mr-1 ml-0;
}

.half .initialPrice,
.half .salePrice,
.half .labelFrom {
  @apply text-xs;
  font-size: 0.75rem;
}
.half .discountAmount {
  @apply text-xs;
  font-size: 0.75rem;
  padding: 0.1875rem 0.3125rem;
}

.ctaAddContainer {
  @apply relative px-2.5 md:px-0;
  transition: transform 0.3s;
}
.ctaAddToBasket {
  @apply flex justify-center items-center flex-1 w-full h-11 mt-3 text-sm text-primaryB bg-primaryA uppercase;
  z-index: 15;
}
.half .ctaAddToBasket {
  @apply h-8;
}

.dropdown {
  @apply fixed bottom-0 right-0  w-full bg-primaryB px-4 pt-5 pb-8 cursor-default z-50 opacity-0;
  animation-name: slideInUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}
.dropdownAlt {
  bottom: -100%;
  animation-name: slideInUpAlt;
}
.backdrop {
  @apply fixed z-40 inset-0 opacity-0 lg:hidden;
  background-color: var(--backdrop);
  animation: fadeIn 0.25s linear;
  animation-fill-mode: forwards;
}
.sizeSelector {
  @apply mt-5 w-full;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideInUp {
  from {
    opacity: 1;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideInUpAlt {
  from {
    opacity: 1;
    bottom: -100%;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}
