.container {
  @apply p-10 bg-primaryB;
  @screen md {
    @apply flex flex-col pt-0;
    flex: 1;
    width: 29rem;
  }
}

.title {
  @apply mb-4 pb-2 font-inter text-3xl font-medium uppercase;
}

.text {
  @apply mb-5 text-sm;
}
