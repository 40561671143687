.container {
  @apply w-full relative py-4;
}
.content li {
  @apply py-0 font-inter text-sm;
}
.content.newDescription li {
  @apply pt-1 pb-0.5;
}

.descriptionCta {
  @apply flex flex-col w-full relative py-2 border-b border-grey300 cursor-pointer;
  user-select: text;
}
.descriptionCta.open {
  @apply pb-4;
}
.descriptionCta:first-of-type {
  @apply border-t;
}
.descriptionCta div {
  @apply text-left;
}

[dir='rtl'] .descriptionCta div {
  @apply text-right;
}

.descriptionCta ul {
  @apply block max-h-0 opacity-0 overflow-hidden;
  transition: opacity 0.4s;
}
.descriptionCta.open ul {
  @apply max-h-full opacity-100;
}
.tabIcon {
  @apply absolute right-2;
  top: 1.25rem;
}
[dir='rtl'] .tabIcon {
  @apply left-2;
  right: unset;
}
.descriptionCta + .newDescription {
  @apply mt-6;
}
