.container {
  @apply flex flex-col justify-center items-center;
}

.img {
  @apply block w-full max-w-xs sm:max-w-lg mb-10;
}

.details {
  @apply flex flex-col justify-center items-center w-full max-w-xs sm:max-w-xl text-center;
}

.title {
  @apply mb-2.5 typo-gilda-title;
}
.subtitle {
  @apply mb-2.5 typo-inter-entitled font-medium uppercase;
}
.text {
  @apply hidden md:block mb-5 typo-lora-text-m;
}

.selectOpt,
.add {
  @apply w-full max-w-xs sm:max-w-sm mb-2;
}
.add {
  @apply mb-5;
}
.opt,
.optSel {
  @apply block w-full text-center;
}
.optSel {
  @apply pl-5;
}
.input {
  @apply h-12 w-full max-w-xs sm:max-w-sm my-2 p-1 border border-primaryA text-center typo-inter-text;
}
