.items {
  @apply flex flex-row w-full bg-grey50 xl:hidden overflow-hidden;
}

.item {
  @apply border-t border-b border-r border-solid;
  flex: 3 3 0%;
  border-color: #c5c5c5;
}
.item:last-of-type {
  @apply border-r-0;
}
.item.lite {
  flex: 2 2 0%;
}

.buttonContainer {
  @apply flex items-center justify-center mx-0;
  height: 3rem;
}

.buttonContainer > span {
  @apply text-sm font-medium text-primaryB bg-primaryA border-0 py-1 px-2.5 rounded-full;
}
