.container {
  @apply w-full bg-white pl-3;
  /* padding: 0 1rem 1.25rem; */
  @screen lg {
    padding: 0 2.5rem 1.25rem;
  }
}

.blur {
  @apply block w-0;
}

.rightSide {
  @apply absolute w-1/4 h-20;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  right: 0;
}

.content {
  @apply w-full flex flex-row justify-start overflow-auto;
  scrollbar-width: none;
  @screen lg {
    @apply justify-center flex-wrap;
  }
}

.content::-webkit-scrollbar {
  display: none;
}

.content > a:last-child,
.content > button:last-child {
  @apply mr-3;
}

.buttonLink {
  @apply flex justify-center items-center px-5 mb-4 mt-2 py-3 border border-black font-medium text-sm uppercase;
  white-space: nowrap;
  margin-right: 0.625rem;
  transition: background 0.3s;
}

.buttonLink:hover,
.isSelected {
  @apply text-white bg-black;
}

.isSelected:hover {
  @apply cursor-default;
  @screen lg {
    background: #343333;
  }
}

.button {
  @apply font-medium text-sm uppercase;
}
