.container {
  @apply flex-col w-full box-border relative;
  margin: 2rem auto 1.25rem;
}

.title {
  @apply w-full relative px-2 pb-8 xl:px-4 font-gilda text-2xl font-normal text-center leading-7 overflow-hidden overflow-ellipsis whitespace-nowrap;
  font-size: 2rem;
}

.lookBlock {
  @apply flex flex-col relative items-center justify-center lg:flex-row mb-9;
}

.pictureAndPoints {
  @apply flex relative justify-center items-center w-full;

  @screen lg {
    width: 32.5rem;
  }
}
.mediaContainer {
  @apply relative;
}

.mediaContainer img {
  @apply block w-full;
}

.card {
  @apply hidden;
  @screen lg {
    @apply flex justify-center items-center;
    width: 23rem;
    margin-left: 6.25rem;
  }
}

.lookPoint {
  @apply w-8 h-8 absolute rounded-full cursor-pointer overflow-hidden z-0;
  transform: translate(-50%, -50%);
  transition: width 0.4s, height 0.4s;
  animation: pulse;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.icon {
  @apply w-8 h-8 absolute text-primaryB rounded-full bg-primaryA text-center;
  font-size: 2rem;
  line-height: 1.75rem;
  transform: translate(-50%, -50%);
}

.dropdown {
  @apply fixed w-full p-2 pb-24 bg-white cursor-default z-40 overflow-y-auto;
  height: 100vh;
  top: 100%;
  animation-name: slideInUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}

.row {
  @apply flex flex-row justify-center items-end flex-wrap w-full relative my-5;
}
.col {
  @apply w-1/2 box-border  mb-5;
}

.row > .col:first-child,
.isEven > .col:last-child {
  @apply flex flex-col px-2 justify-center items-center w-full;
}

.close {
  @apply flex p-1   cursor-pointer items-center justify-start;
  width: fit-content;
}
.iconClose {
  @apply h-8 w-8;
}

.close > p {
  @apply uppercase ml-1;
}

[dir='rtl'] .close {
  @apply mr-1 ml-0;
}

.isInListing {
  margin: 0 auto;
}
.isInListing .lookBlock {
  margin-bottom: 3.75rem;
  @screen lg {
    margin-bottom: 3.875rem;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 90%);
  }
  70% {
    box-shadow: 0 0 0 1.25rem rgb(255 255 255 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
  }
}

@keyframes slideInUp {
  from {
    top: 100%;
  }
  to {
    top: 3.375rem;
  }
}
