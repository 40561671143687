.root {
  @apply h-full bg-primaryB mx-auto transition-colors duration-150;
  max-width: 2460px;
}

.no_overflow {
  overflow: inherit !important;
}

.head {
  @apply bg-primaryB;
}
@screen xl {
  .head {
    @apply w-full h-auto relative z-20;
    transition: background 0.5s;
  }
}

.full_width {
  @apply w-full;
}

.no_border {
  @apply border-none;
}

.height_auto {
  @apply h-auto;
}

.no_margin {
  @apply mt-0;
}

.animation {
  transition: visibility 0.3s, opacity 0.3s cubic-bezier(0.75, 0.02, 0.5, 1) 0s;

  @screen xl {
    transition: all 0s ease 0s;
    animation: slide-out-top 0.3s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
