.container {
  @apply w-full relative mt-4 ml-3 py-3 px-0;

  @screen lg {
    @apply ml-0 p-0;
    max-width: 18.75rem;
  }
}

[dir='rtl'] .container {
  @apply mr-3 ml-0;

  @screen lg {
    @apply mr-0;
  }
}

.content {
  @apply flex flex-row justify-start items-center relative;
}

.label {
  @apply flex justify-start items-center flex-nowrap w-full font-medium text-primaryA text-left uppercase whitespace-nowrap cursor-pointer select-none;
  font-size: 0.875rem;

  @screen lg {
    font-size: 0.75rem;
  }
}

.title {
  min-width: 5.3125rem;
}

.box {
  @apply w-0 h-0 opacity-0;
}

.switch {
  @apply inline-block relative left-2 right-2 bg-grey300 cursor-pointer rounded-2xl;
  width: 2.375rem;
  height: 1.25rem;
  transition: 0.3s;
}

.switch:before {
  @apply absolute -left-px bg-primaryB rounded-full;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.checked {
  @apply bg-gray-800;
}
.checked:before {
  @apply left-px;
  transform: translateX(1.1875rem);
}

.disabled {
  @apply opacity-50;
}
