.container {
  @apply w-full relative;

  @screen lg {
    max-width: 18.75rem;
  }
}

.content {
  @apply flex flex-row justify-start items-center relative pt-7 pb-6 px-3  border-t border-grey300;

  @screen lg {
    @apply py-5 px-0;
  }
}

.title {
  @apply flex justify-between items-center flex-nowrap w-full font-medium text-primaryA text-left uppercase whitespace-nowrap cursor-pointer select-none;
  font-size: 0.875rem;
  padding-right: 0.1rem;

  @screen lg {
    font-size: 0.75rem;
  }
}

.box {
  @apply w-0 h-0 opacity-0;
}

.switch {
  @apply inline-block relative bg-grey300 cursor-pointer rounded-2xl;
  width: 2.375rem;
  height: 1.25rem;
  transition: 0.3s;
}

.switch:before {
  @apply absolute -left-px bg-primaryB rounded-full;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.checked {
  @apply bg-gray-800;
}
.checked:before {
  @apply left-px;
  transform: translateX(1.1875rem);
}

.disabled {
  @apply opacity-50;
}
