.userSettings {
  @apply hidden;

  @screen xl {
    @apply flex;
  }
}

.transparent span {
  @apply text-primaryB;
}
.transparent svg path {
  stroke: white;
}
