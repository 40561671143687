.container {
  @apply flex justify-center w-full relative block-cms-container-mobile mt-0;
  padding-bottom: 56.25%;
  height: 0;

  @screen md {
    @apply mx-auto px-4;
  }
  @screen xl {
    @apply mx-auto px-11;
  }
}

.container.minimalist {
  @apply my-0;
}

.player {
  @apply absolute top-0 left-0;
}

.layout {
  @apply absolute flex justify-center w-full h-full items-center;
}
.cta {
  @apply cursor-pointer;
}

.link {
  @apply absolute  text-2xl font-extrabold cursor-pointer bg-primaryA opacity-30 p-5;
  top: 50%;
  color: #fff;
}

.link:hover {
  @apply opacity-80;
  transition: opacity 0.3s ease-in-out;
}
.controllers {
  @apply flex flex-col absolute bottom-0 justify-between items-center w-full  bg-primaryB opacity-40 px-4;
  animation: fadeIn 0.3s ease-in-out;
}
.mobile .controllers {
  @apply absolute opacity-100;
  bottom: -80px;
}

.minimalist .controllers {
  @apply absolute bottom-0 bg-transparent;
}

.controllers.noControls {
  @apply flex flex-row;
}

.controllers:hover {
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
}
.mobile .controllers:hover {
  @apply opacity-100;
}

.top {
  @apply flex justify-between items-center w-full;
}

.leftSide,
.rightSide {
  @apply flex justify-between items-center;
}

.minimalist .leftSide {
  @apply hidden;
}

.minimalist .rightSide {
  @apply ml-auto items-end;
}

.minimalist .rightSide > div {
  @apply m-0;
}

.play,
.mute {
  @apply text-2xl cursor-pointer my-3;
  line-height: 0;
  color: #fff;
}

.time {
  @apply ml-4;
}

[dir='rtl'] .time {
  @apply mr-4 ml-0;
}

.progress {
  @apply w-full z-40 pt-3 pb-4 cursor-pointer;
}

.minimalist .progress {
  @apply pb-1;
}

.bar {
  @apply w-full mt-0 mb-0 cursor-pointer z-0;
}

.bar > div {
  @apply bg-primaryA;
}

.loadingImg {
  @apply absolute top-0 left-0 w-full h-full;
}

.loadingImg.fade {
  animation: fadeOut 1.5s ease-in-out;
}

.description {
  @apply text-sm text-primaryA cursor-default;
}

.volumeBar {
  @apply mr-1 bg-primaryA;
  width: 70%;
  height: 0.4rem;
  border-radius: 0.3125rem;
  -webkit-appearance: none;
}

.volumeBar::-webkit-slider-thumb {
  @apply bg-primaryA cursor-pointer;
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  -webkit-appearance: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
