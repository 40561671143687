.container {
  @apply flex flex-col items-center w-full box-border relative;
  margin: 1rem auto 1.25rem;
}

.title {
  @apply w-full relative px-2 pb-4 xl:px-4 font-gilda text-4xl font-normal leading-7 overflow-hidden overflow-ellipsis whitespace-nowrap;
  font-size: 2rem;
}

.mediaContainer {
  @apply relative mb-9 w-full;
}
.mediaContainer img {
  @apply block w-full;
}

.lookPoint {
  @apply w-8 h-8 absolute rounded-full cursor-pointer overflow-hidden z-0;
  transform: translate(-50%, -50%);
  transition: width 0.4s, height 0.4s;
  animation: pulse;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
.lookPoint.expand {
  @apply items-start pt-1 rounded-lg bg-primaryB z-10;
  width: 21rem;
  height: 32rem;
  border: 0.0625rem solid var(--grey200);
  box-shadow: 0.2px 0.2px 0 rgb(12 12 12 / 70%);
  animation-name: none;
}

.icon {
  @apply w-8 h-8 absolute text-primaryB rounded-full bg-primaryA text-center;
  font-size: 2rem;
  line-height: 1.75rem;
  transform: translate(-50%, -50%);
}

.card {
  @apply hidden  w-0;
}
.expand .card {
  @apply w-full block pt-2.5;
}

.isInListing {
  margin: 0 auto;
}
.isInListing .mediaContainer {
  margin-bottom: 3.875rem;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 90%);
  }
  70% {
    box-shadow: 0 0 0 1.25rem rgb(255 255 255 / 0%);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
  }
}
