.container {
  @apply mt-3 mb-0 pb-1.5 border-grey300 border-b z-50;
  transition: all 0.3s;
  @screen lg {
    @apply mt-0 border-t border-b-0 transition-none z-0;
    transition: none;
  }
}
.container:last-child {
  @apply lg:border-b;
}
.container.opened {
  transform: translateX(100vw);
  @screen lg {
    @apply transform-none;
  }
}

.filtersList {
  @apply flex flex-row flex-wrap h-full overflow-y-auto overflow-x-hidden mb-2.5;
  height: calc(100% - 3.875rem);
  max-height: 20rem;
}

.filtersList::-webkit-scrollbar {
  width: 0.4375rem;
}

.filtersList::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: rgba(0, 0, 0, 0);
}

.filtersList:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.filtersList.searchable {
  @apply block;
}

.buttonItemContainer {
  @apply ml-3 lg:ml-0;
}

[dir='rtl'] .buttonItemContainer {
  @apply mr-3 lg:mr-0 ml-0;
}

.cta {
  @apply w-full relative uppercase font-medium text-left mb-1.5 pt-4 py-2.5 px-3 text-primaryA whitespace-nowrap;
  font-size: 0.875rem;

  @screen lg {
    @apply pt-5 pb-2.5 p-0;
    font-size: 0.75rem;
  }
}
.arrowDown {
  @apply absolute top-5 right-4;
  transform: rotate(-90deg);
  transition: transform 0.3s;

  @screen lg {
    @apply top-1/2 right-0;
  }
}

.disabled .filtersList,
.disabled .inputSearch {
  @apply hidden;
}
.disabled .arrowDown {
  transform: rotate(90deg);
}

.inputSearch {
  @apply block mx-auto mb-2 p-2 border border-grey200;
  width: 95%;
  font-size: 1rem;
  line-height: 0.875rem;

  @screen lg {
    @apply w-full;
    font-size: 0.875rem;
  }
}
.inputSearch:focus {
  @apply outline-none border border-solid border-primaryA;
}
