.container {
  @apply w-full border-primaryA relative block-cms-container-mobile;
  @screen md {
    @apply block-cms-container-tablet;
  }
  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.content {
  @apply flex flex-col justify-center items-center w-full relative border-primaryA;
  border-top-width: 0.0625rem;
}
.container:first-child .content {
  @apply border-none;
}

.row {
  @apply flex flex-row justify-start items-start w-full relative mt-3 lg:m-0 py-0 opacity-100;
}

.fadeIn {
  opacity: 0.3;
  transition: opacity 0.3s;
}

.layout {
  @apply h-full w-full fixed top-0 left-0 cursor-pointer z-40 opacity-0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.3s linear;
  animation-fill-mode: forwards;
}

.filters {
  @apply h-full fixed bottom-0 bg-primaryB box-border z-50 invisible;
  width: 85%;
  left: -100%;
  transition: visibility 0.25s, left 0.25s;
  @screen lg {
    @apply sticky left-0 overflow-y-scroll z-10;
    width: 0;
    height: calc(100vh - 7.9375rem);
    top: 7.9375rem;
    transform: none;
    transition: visibility 0.25s, width 0.25s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      @apply hidden;
    }
  }
  @screen xl {
    height: calc(100vh - 4.25rem);
    top: 4.25rem;
  }
}
.openFilters .filters {
  @apply visible left-0;
  @screen lg {
    @apply mr-6;
    width: 14.75rem;
  }
  @screen xl {
    width: 15.625rem;
  }
}

[dir='rtl'] .openFilters .filters {
  @screen lg {
    @apply ml-6 mr-0;
  }
}

.cards {
  @apply w-full relative lg:mt-0.5;
  transition: width 0.25s;
}
.openFilters .cards {
  @apply w-full;
  @screen lg {
    width: calc(100% - 14.75rem);
  }
  @screen xl {
    width: calc(100% - 15.625rem);
  }
}

.loading {
  @apply h-full w-full fixed left-0  bottom-0 bg-white bg-opacity-0 z-50;
}

.msg {
  @apply border-t border-primaryA text-center text-lg font-medium uppercase pt-14 pb-11  lg:pb-0;
}
.msgLite {
  @apply w-full flex justify-center  text-center text-lg font-normal uppercase mt-10 pt-6 pb-0;
}
.msgPush {
  @apply w-full flex justify-center  text-center text-lg font-normal uppercase pt-3 lg:pt-0 pb-6;
}

.outletButton {
  @apply flex w-auto justify-center items-center px-5 mb-4 mt-2 py-3 border-2 border-black font-normal text-sm uppercase;
  white-space: nowrap;
  transition: background 0.3s;
}
.msgPush .outletButton {
  @apply w-full mx-auto mb-2 p-2.5 font-inter text-xs text-primaryA text-center font-medium bg-primaryB border-2 border-primaryA uppercase;
  max-width: 15.25rem;
}

.outletButton:hover {
  @apply text-white bg-black;
}

.msgPush.msgSales .outletButton {
  @apply text-sales border-sales;
}
.msgPush.msgSales .outletButton:hover {
  @apply text-white bg-sales;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
