.root {
  @apply h-full w-full fixed left-0  bottom-0 bg-primaryB  z-50 inline-flex text-center items-center justify-center leading-7;
  animation: fadeIn 0.5s;
  opacity: 0.7;
}

.rainbow {
  animation: smoothBlink 1.2s infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes smoothBlink {
  from {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
