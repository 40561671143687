.title + .list {
  @apply mt-1;
}

.link {
  @apply block w-full py-1 text-sm text-primaryB;
  transition: all 0.3s;
}
.link:hover {
  @apply text-grey300;
}
