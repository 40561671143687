.modal {
  @apply overflow-auto max-w-full relative;
  width: 45rem;
}

.modal::-webkit-scrollbar {
  width: 0.4375rem;
}

.modal::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: rgba(0, 0, 0, 0);
}

.modal:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  @apply flex flex-col h-full mx-4 my-0;
  @screen md {
    margin: 1.875rem 8.5rem 5rem 8.5rem;
  }
}

.modalContent h2 {
  @apply font-gilda text-center mb-6 text-primaryA;
  font-size: 2.125rem;
  line-height: 3rem;
}

.messageContainer {
  margin-bottom: 1.875rem;
  min-height: 3rem;
}

.messageInfo {
  @apply font-inter text-sm text-primaryA;
}

.productModalBlock {
  @apply w-full mb-6;
}

.selector {
  @apply w-full mb-3;
}

.selectContainer {
  @apply relative;
}
.select {
  @apply w-full h-10 flex items-center justify-between relative mb-2 border font-medium  bg-primaryB uppercase cursor-pointer;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
  font-size: 0.75rem;
  border-color: var(--grey300);
}
.select::after {
  content: '';
  @apply w-full absolute bottom-0 left-0 border-b;
  border-color: var(--grey300);
}
[dir='rtl'] .select {
  padding: 0.375rem 1rem 0.375rem 0.5rem;
}
.chevron {
  @apply h-4 w-4 absolute top-3 right-2;
  transition: transform 0.3s;
  transform: rotate(180deg);
}

.createListButtonContainer,
.buttonsContainer {
  @apply w-full  flex flex-col-reverse  py-4 md:static md:block md:p-0;
}

.form {
  @apply flex flex-col justify-between h-full md:block;
}

.createListButtonContainer {
  @apply md:mt-28;
}

.button {
  @apply w-full mt-3;
}

.button span {
  @apply flex flex-row justify-between items-center;
}

.button svg {
  @apply mr-2;
}
[dir='rtl'] .button svg {
  @apply ml-2 mr-0;
}

.buttonDisabled {
  opacity: 0.45;
}

.buttonDisabled:hover {
  @apply bg-primaryA;
}

.createList {
  @apply bg-transparent box-border text-primaryA border border-solid border-primaryA;
  @screen md {
    @apply h-auto float-right mt-2 border-none p-0 normal-case font-normal text-sm shadow-none select-none;
  }
}

.createList:hover {
  @apply bg-grey200 md:bg-transparent;
}

.createListIcon {
  @apply hidden md:inline;
}

.createListLabel {
  @apply relative;
}

.createListLabel:after {
  @apply absolute w-0 border-b border-primaryA hidden md:block;
  content: '';
  bottom: -0.1875rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
}

.createListLabel:hover:after {
  @apply w-full;
}
