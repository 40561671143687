.menu {
  @apply relative;
}

@screen lg {
  .menu a {
    width: 16.25rem;
  }
}

.title {
  @apply font-medium text-sm mt-6 mb-4 uppercase text-center lg:text-left;
}

.catTitle {
  @apply font-medium text-sm uppercase py-4 border-t border-grey100;
}

.subCatTitle {
  @apply font-normal text-sm normal-case my-1.5;
}
