.brandsIndex {
  @apply py-4 border-t-2 border-b-2 border-solid border-primaryA bg-primaryB md:top-0 overflow-scroll md:overflow-hidden shadow-none z-10;
  position: -webkit-sticky;
  position: sticky;
  top: 3.375rem;

  -ms-overflow-style: none;
  scrollbar-width: none;

  @screen lg {
    @apply top-0;
  }
}

.brandsIndex::-webkit-scrollbar {
  @apply hidden;
}

.brandsIndex ol {
  @apply flex md:justify-around;
}

.brandsIndex li {
  @apply font-gilda list-none px-2 md:px-0;
}

.indexLetter {
  @apply font-gilda text-primaryA text-xl uppercase font-semibold cursor-pointer;
}

.indexLetterGray {
  @apply text-grey400 font-semibold cursor-default;
}

.indexSelected {
  @apply text-charitable;
}
