@keyframes scaleUp {
  0% {
    transform: scale(0) translateY(-5%);
  }
  50% {
    transform: scale(1.25) translateY(-5%);
  }
  100% {
    transform: scale(1) translateY(-5%);
  }
}

.container {
  @apply border-grey300 border-t z-50;
  transition: all 0.3s;
  @screen lg {
    @apply border-t transition-none z-0;
    transition: none;
  }
}
.container.opened {
  transform: translateX(100vw);
  @screen lg {
    @apply transform-none;
  }
}

.filtersList {
  @apply flex flex-row flex-wrap h-full overflow-y-auto overflow-x-hidden;
  max-height: 20rem;

  @screen lg {
    @apply pb-3;
  }
}

.filtersList::-webkit-scrollbar {
  width: 0.4375rem;
}

.filtersList::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: rgba(0, 0, 0, 0);
}

.filtersList:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.filtersList.searchable {
  @apply block;
  height: 21rem;
}

.cta {
  @apply w-full relative uppercase font-medium text-left py-6 px-3 text-primaryA whitespace-nowrap;
  font-size: 0.875rem;

  @screen lg {
    @apply py-5 px-0;
    font-size: 0.75rem;
  }
}
.arrowDown {
  @apply absolute top-8 right-4;
  transform: rotate(-90deg);
  transition: transform 0.3s;

  @screen lg {
    @apply top-6 right-0;
  }
}

.disabled .filtersList,
.disabled .inputSearch {
  @apply hidden;
}
.disabled .arrowDown {
  transform: rotate(90deg);
}

.inputSearch {
  @apply block mx-auto mb-2 p-2 border border-grey200;
  width: 95%;
  font-size: 1rem;
  line-height: 0.875rem;

  @screen lg {
    @apply w-full;
    font-size: 0.875rem;
  }
}

/* CHECKBOX */
.containerCheckbox {
  @apply w-full relative mx-3 px-0;

  @screen lg {
    @apply mx-0;
    max-width: 18.75rem;
  }
}

.containerCheckbox .containerCheckbox {
  @apply ml-5;
}

[dir='rtl'] .containerCheckbox .containerCheckbox {
  @apply mr-5 ml-0;
}

.contentCheckbox {
  @apply flex flex-row justify-start items-center py-3 relative;

  @screen lg {
    @apply py-1.5;
  }
}

.containerCheckbox:first-child .contentCheckbox {
  @apply pt-0;
}

.boxCheckbox {
  @apply bg-primaryB cursor-pointer;
  min-height: 1.25rem;
  max-height: 1.25rem;
  min-width: 1.25rem;
  max-width: 1.25rem;
  border: 0.0625rem solid var(--grey300);
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.boxCheckbox.checked {
  background: var(--grey600);
}

.titleCheckbox {
  @apply flex flex-row justify-start items-center w-full pl-2 pr-1 text-grey600 text-left leading-4 cursor-pointer select-none;
  font-size: 1rem;

  @screen lg {
    font-size: 0.875rem;
  }
}

.containerCheckbox svg {
  @apply absolute left-0 mt-0.5 cursor-pointer;
  transform: scale(0) translateY(-5%);
  animation: scaleUp 0.5s ease-in-out forwards;
}

.containerCheckbox:not(.disabled):hover .titleCheckbox,
.containerCheckbox:not(.disabled):hover svg path {
  color: var(--primaryA);
}
.containerCheckbox:not(.disabled):hover .boxCheckbox {
  border-color: var(--primaryA);
}

.disabled .titleCheckbox,
.disabled .boxCheckbox {
  @apply text-grey400 cursor-default;
}

.disabled svg {
  @apply cursor-default;
}

.totalCheckbox {
  @apply ml-1 font-inter font-normal normal-case bg-grey100 text-primaryA;
  padding: 0.15625rem 0.3125rem;
  font-size: 0.75rem;
  border-radius: 0.1875rem;
}

[dir='rtl'] .totalCheckbox {
  @apply mr-1 ml-0;
}

.childHirerachicalList {
  @apply w-full flex flex-row flex-wrap overflow-y-auto overflow-x-hidden;
}

.buttonAction {
  @apply w-full uppercase font-medium text-center py-4 px-2.5 text-primaryA whitespace-nowrap;
}

.containerRange {
  @apply inline;
}

.formRange {
  @apply flex flex-row justify-between items-center space-x-2 pb-2;
}

.containerButtonCheckbox {
  @apply mb-3 mr-2.5 border border-grey400 overflow-hidden;
  border-radius: 0.1875rem;
}
[dir='rtl'] .containerButtonCheckbox {
  @apply ml-2.5 mr-0;
}
.containerButtonCheckbox :hover:not(.disabled) {
  @apply border-primaryA;
}

.titleButtonCheckbox {
  @apply text-primaryA border border-transparent lg:text-grey600;
  width: 5.625rem;
  height: 2.5rem;
  font-size: 0.875rem;

  @screen lg {
    width: 4rem;
  }
}

.checkedButtonCheckbox {
  @apply text-primaryB;
  background: var(--grey600);
}

.disabledButtonCheckbox {
  @apply opacity-50;
}

.inputSearch {
  @apply block mx-auto mb-2 p-2 border border-grey200;
  width: 95%;
  font-size: 1rem;
  line-height: 0.875rem;

  @screen lg {
    @apply w-full;
    font-size: 0.875rem;
  }
}
.inputSearch:focus {
  @apply outline-none border border-solid border-primaryA;
}
