.container {
  @apply flex flex-row justify-between relative mb-3 pt-0 pb-3 border-gray-300;
  max-width: 80%;
  font-size: 0.875rem;
  @screen lg {
    @apply mb-0 py-3;
    font-size: 0.75rem;
  }
}

.pagination {
  @apply relative flex flex-row flex-wrap items-center justify-center;
}
.paginationItem {
  @apply block relative mx-1.5 my-1 lg:my-1.5 px-2.5 py-1.5 border-grey300 border cursor-pointer;
}
.paginationItem:hover {
  @apply bg-grey100;
  transition: background-color 0.3s;
}
.paginationItem.selected {
  @apply border-2 border-primaryA;
}
.paginationItem.chevron {
  @apply transform rotate-180;
}
