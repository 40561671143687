.container {
  @apply w-full relative block-cms-container-mobile;
  @screen md {
    @apply block-cms-container-tablet mt-16;
  }

  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.content {
  @apply flex flex-col items-center w-full px-0 pt-7 pb-5 justify-center border-t border-primaryA box-border;
}

.title {
  @apply xl:mx-11 text-3xl font-normal text-primaryA font-gilda text-center;
}

.description {
  @apply flex flex-col items-center w-full box-border relative;
}

.text {
  @apply w-full mb-2 text-base font-lora font-normal text-center;
  max-width: 50rem;
}

.additionalText {
  @apply w-full mb-2 py-0 px-2.5 lg:px-11;
}

.cta {
  @apply w-full mt-1 font-inter font-medium text-grey600 uppercase underline cursor-pointer;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
