.container {
  @apply flex flex-col items-center w-full box-border relative block-cms-container-mobile;
  @screen md {
    @apply w-1/3 mt-1 mb-1.5 mx-auto px-0.5;
  }
  @screen xl {
    @apply mt-2 mb-5 px-0.5;
  }
}

.media {
  @apply block w-full h-auto mb-4 overflow-hidden;
}
.media img {
  @apply block w-full;
  transition: transform 1s;
  transform: scale(1);
}
