.container {
  @apply flex flex-row justify-items-start items-start flex-wrap relative w-full h-auto overflow-x-hidden;
}

.media {
  @apply relative h-auto w-full cursor-pointer;
}
.full {
  @apply w-full;
}
.half {
  @apply w-1/2;
}
.third {
  @apply w-1/3;
}

.img {
  @apply w-full h-full mx-auto my-0;
  max-width: 45rem;
}
