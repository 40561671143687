.container {
  @apply flex items-center justify-center;
  height: 3rem;
}

.slider {
  @apply relative w-full;

  @screen lg {
    width: 98%;
  }
}

.slider__track,
.slider__range,
.slider__left_value,
.slider__right_value {
  @apply absolute;
}

.slider__track,
.slider__range {
  border-radius: 0.1875rem;
  height: 0.25rem;
}

.slider__track {
  @apply w-full bg-grey300;
  z-index: 1;
}

.slider__range {
  @apply bg-primaryA;
  z-index: 2;
}

.slider__left_value,
.slider__right_value {
  @apply mt-5 text-sm;
}

.slider__left_value {
  left: 0px;
}

.slider__right_value {
  right: 0px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  @apply absolute w-full h-0 outline-none pointer-events-none;

  @screen lg {
    width: 98%;
  }
}

.thumb__zindex_3 {
  z-index: 3;
}

.thumb__zindex_4 {
  z-index: 4;
}

.thumb__zindex_5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  @apply relative bg-primaryB;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 0.125rem var(--primaryA);
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin-top: 0.25rem;
  pointer-events: all;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  @apply relative bg-primaryB;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 0.125rem var(--primaryA);
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin-top: 0.25rem;
  pointer-events: all;
}

.inputs {
  @apply flex flex-row items-center justify-start pb-2 space-x-2;
}

.inputRange {
  @apply w-full px-2 text-sm outline-none typo-inter-text;
  height: 3rem;
  border: 0.0625rem solid var(--grey300);
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
}

.inputRange::-webkit-inner-spin-button {
  @apply hidden;
}

.currencyWrap {
  @apply relative w-full;
}

.currencyCode {
  @apply absolute text-sm right-2 typo-inter-text text-primaryA;
  top: 0.925rem;
}
