.container {
  @apply w-full relative;
}

.controls {
  @apply flex flex-col justify-center w-16 h-full absolute top-0 overflow-hidden lg:hidden;
  z-index: 12;
}
.visible .controls {
  @apply lg:flex;
}
.controls.left {
  @apply left-2.5 items-start;
}
.controls.right {
  @apply right-2.5 items-end;
}
.arrow {
  @apply flex flex-col justify-center items-center w-10 h-10;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
}
.arrowLeft svg {
  transform: rotate(180deg);
}

.dots {
  @apply flex lg:hidden flex-row justify-center items-center absolute left-2.5 top-0;
}
[dir='rtl'] .dots {
  @apply right-2.5;
  left: unset;
}
.dot {
  @apply relative rounded-full bg-grey300;
  width: 0.375rem;
  height: 0.375rem;
  margin: 0 0.1875rem;
}
.dot.enable {
  @apply bg-primaryA;
}

.slider {
  @apply w-full relative overflow-hidden;
}
.slider.half > div {
  @apply pt-5;
}
.slide {
  @apply w-full h-auto absolute top-0 left-0;
  visibility: hidden;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.slide:first-of-type {
  @apply relative;
}
.current {
  visibility: visible;
}
.currentForward {
  animation-name: next;
}
.currentBackward {
  animation-name: prev;
}
.previousForward {
  visibility: visible;
  animation-name: nextReverse;
}
.previousBackward {
  visibility: visible;
  animation-name: prevReverse;
}
.altAnim .currentForward {
  animation-name: nextAlt;
}
.altAnim .currentBackward {
  animation-name: prevAlt;
}
.altAnim .previousForward {
  visibility: visible;
  animation-name: nextReverseAlt;
}
.altAnim .previousBackward {
  visibility: visible;
  animation-name: prevReverseAlt;
}
@keyframes next {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes prev {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes nextReverse {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes prevReverse {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes nextAlt {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}
@keyframes prevAlt {
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
}
@keyframes nextReverseAlt {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}
@keyframes prevReverseAlt {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}
