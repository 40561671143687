.letterBlock {
  @apply md:flex py-7 border-t-2 border-solid border-primaryA;
}

.letterBlock:first-child {
  @apply border-t-0;
}

.brandsBlock {
  @apply w-full md:grid md:grid-flow-col md:grid-cols-3 xl:grid-cols-5 px-10 xl:px-2;
}

.letter {
  @apply font-gilda text-primaryA px-1 md:px-10 xl:px-2;
  width: 3.75rem;
  margin-top: 0.625rem;
  font-size: 1.875rem;
}

.brand {
  @apply font-inter text-sm uppercase text-primaryA font-normal mt-2 md:mt-0;
  font-size: 0.75rem;
  line-height: 1.375rem;
}

.brandSuffix {
  @apply inline-block font-inter text-xxs font-medium ml-1 normal-case;
  padding: 0.15625rem 0.3125rem;
  border-radius: 0.1875rem;
  transform: translateY(-0.0625rem);
}

[dir='rtl'] .brandSuffix {
  @apply mr-1 ml-0;
}

.brandIsNew {
  @apply bg-primaryA text-primaryB;
}

.brandDiscount {
  @apply bg-gray-100 text-primaryA;
}

.displayNone {
  @apply hidden;
}

.anchor {
  @apply relative;
}
