.container {
  @apply flex  justify-around items-center text-sm;
  padding: 0.25rem;
  height: 3rem;
  border: 0.0625rem solid #ccc;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0;
}

.formItem {
  @apply w-full h-full  flex flex-col relative justify-center 2xl:justify-between items-start;

  @screen md {
    @apply flex-row justify-start items-center w-full;
  }
}
.formLabel {
  @apply absolute  text-sm bg-primaryB px-2;
  transform: translate(0, -140%);
}

.formLabel.darkTheme {
  @apply bg-primaryA text-primaryB;
}

.arrowDown {
  @apply absolute right-0 mr-1 flex items-center;
  transform: rotate(90deg);
}
[dir='rtl'] .arrowDown {
  @apply ml-1 mr-0;
}

.select {
  @apply w-full h-full bg-transparent  z-10 cursor-pointer;
  margin-left: 0.4375rem;
}
