.item {
  @apply pl-5 pr-2.5;

  @screen xl {
    @apply table-cell p-0;
    width: 1%;
  }
}

.item + .item {
  @apply border-t border-solid;
  border-color: var(--grey200);

  @screen xl {
    @apply border-0;
  }
}

.buttonContainer {
  @apply relative;
}

.link {
  @apply my-1 flex flex-wrap justify-between items-center whitespace-nowrap uppercase text-primaryA;

  @screen xl {
    @apply block text-center;
  }
}
@screen md {
  .link:hover {
    color: var(--primaryA) !important;
  }
}

.title {
  @apply inline-block my-3 py-1;
  font-size: 0.8125rem;
  background: linear-gradient(#fff, #fff), linear-gradient(#000, #000);
  background-size: 100% 0, 0 1px;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: background 0.2s ease-in-out;

  @screen xl {
    @apply my-2 text-xs;
  }
}

.arrow {
  transition: transform 0.15s cubic-bezier(0.75, 0.02, 0.5, 1);

  @screen xl {
    @apply hidden;
  }
}

.expanded .arrow {
  transform: rotate(90deg);
}

@screen xl {
  .item:hover .link {
    @apply text-primaryA;
  }

  .item:hover .title {
    background-size: 100% 0, 100% 1px;
  }
}

.button {
  @apply absolute w-full h-full top-0 cursor-pointer;

  @screen xl {
    display: none;
  }
}

.container {
  @apply static z-auto left-auto right-auto h-auto overflow-hidden;
  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0.75, 0.02, 0.5, 1);

  @screen xl {
    @apply absolute z-40 left-0 right-0 h-0  invisible bg-primaryB border-solid border-t border-b;
    max-height: inherit;
    transition: all 0.3s cubic-bezier(0.75, 0.02, 0.5, 1);
    transition-delay: 0.2s;
  }
}

.container:hover {
  @screen xl {
    transition: all 0s;
  }
}

.opened .container {
  @screen xl {
    @apply visible border-primaryA;
    min-height: 32.5rem;
    height: auto;
  }
}

.container.stickyTop {
  @screen xl {
    @apply fixed top-0;
  }
}

.container:not(.isExpandable) {
  @apply hidden xl:block;
}

.expanded .container {
  max-height: 100rem;

  @screen xl {
    max-height: inherit;
  }
}

.cols {
  @apply px-0 opacity-0;
  transition: opacity 0.2s cubic-bezier(0.75, 0.02, 0.5, 1);
  transition-delay: 0.1s;

  @screen xl {
    @apply px-11 flex justify-between;
  }
}

.expanded .cols {
  @apply opacity-100;
}

.opened .cols {
  @screen xl {
    @apply opacity-100;
  }
}

/* Important to bypass the visibility attribute added by next/image */
.image {
  visibility: hidden !important;
}

.opened .image {
  visibility: visible !important;
}
