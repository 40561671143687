.container {
  @apply flex w-full justify-center absolute -bottom-10 pt-4 pb-1 px-1 font-inter text-primaryA font-normal text-center text-sm cursor-default z-20;
  animation: fadeIn 0.3s linear forwards;
  background-color: rgba(255, 255, 255, 1);
}

.content {
  @apply w-full;
}

.title {
  @apply pb-1 text-grey600;
  font-size: 0.875rem;
}

.sizes {
  @apply flex flex-row justify-center items-center flex-wrap;
}
.sizes.scrollable {
  @screen lg {
    @apply overflow-y-auto max-h-32;
  }
}

.size {
  @apply flex justify-center items-center relative;
  margin: 0.1875rem 0.25rem;
  transition: all 0.3s;
  @screen lg {
    @apply overflow-y-visible;
  }
}

.cta {
  @apply block relative text-primaryA leading-3 bg-primaryB border border-grey300 rounded-sm cursor-pointer;
  padding: 0.5625rem 0.8125rem;
  font-size: 1rem;
  transition: all 0.25s;
}
.cta:not(.empty, .lowStock):hover,
.cta:not(.empty, .lowStock):focus {
  @apply border-primaryA;
}
.cta.lowStock {
  @apply text-lowstock;
  border-color: #e3cfbd;
}
.cta.lowStock:not(.empty):hover,
.cta.lowStock:not(.empty):focus {
  @apply border-lowstock;
}
.cta.lowStock:not(.empty):active {
  @apply text-primaryB bg-lowstock;
}
.cta:not(.empty):active {
  @apply text-primaryB bg-primaryA;
}
.cta.empty {
  @apply text-grey300 border-grey200;
}

.lastChance {
  @apply invisible absolute top-full mt-1.5 px-0.5 py-0 text-primaryB border-lowstock bg-lowstock rounded-sm whitespace-nowrap z-50;
  min-width: 6.5rem;
  font-size: 0.75rem;
}
.lastChance:before {
  @apply absolute bottom-full border-solid;
  content: '';
  left: 50%;
  margin-left: -0.5rem;
  border-width: 0.1875rem 0.5rem;
  border-color: transparent transparent var(--lowstock);
}
.cta:hover + .lastChance {
  @apply visible;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
