.container {
  @apply flex flex-col lg:flex-row justify-between items-center w-full relative;
  max-width: 100rem;
}

.nav {
  @apply flex flex-col lg:flex-row justify-center items-center;
}
.nav li a {
  color: white;
}
.nav li {
  @apply py-4 text-base;
  transition: all 0.3s;
  @screen lg {
    @apply py-2.5 pr-5 text-sm;
  }
  @screen xl {
    @apply pr-7;
  }
}
.nav li:hover {
  @apply text-grey300;
}

.copyright {
  @apply pt-7 pb-2.5 text-sm text-grey300 uppercase;
  font-size: 0.75rem;
  @screen lg {
    @apply py-2.5  pb-0;
  }
}
