.footer {
  @apply relative mt-5 md:mt-10 bg-primaryA text-primaryB;
  z-index: 19;
}

.line {
  @apply flex flex-wrap flex-row justify-center mx-0 xl:mx-11 py-5 px-4 border-solid border-t border-grey600 xl:px-0;
}

.lineDesktop {
  @apply md:flex;
}

.title {
  @apply text-sm font-medium font-inter uppercase;
  font-size: 0.75rem;
}

.newsletter {
  @apply w-full;
}

@screen md {
  .newsletter {
    width: 44%;
  }
  .social {
    @apply mt-0 ml-8;
    width: 20%;
  }

  [dir='rtl'] .social {
    @apply mr-8 ml-0;
  }
}

@screen lg {
  .newsletter {
    width: 52%;
  }
  .social {
    width: 26%;
  }
}
