.container {
  @apply mt-3 mr-2.5 border border-grey400 overflow-hidden;
  border-radius: 0.1875rem;
}
.container:hover:not(.disabled) {
  @apply border-primaryA;
}

.title {
  @apply text-primaryA border-2 border-transparent lg:text-grey600;
  width: 5.625rem;
  height: 2.5rem;
  font-size: 0.875rem;

  @screen lg {
    width: 4rem;
  }
}

.checked {
  @apply text-primaryB;
  background: var(--grey600);
}

.disabled {
  @apply opacity-50;
}
