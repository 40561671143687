.center {
  @apply pt-2 px-5 pb-4 flex flex-col font-inter;
  width: 15.5rem;
}

.right,
.left {
  @apply pt-2 px-5 pb-4 flex flex-col font-inter;
  width: 21.5625rem;
}
