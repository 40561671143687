.nav {
  @apply w-screen h-auto  fixed bottom-0 overflow-y-auto bg-primaryB  z-50;
  top: 3.5rem;
  left: -100%;
  transition: all 0.25s;

  @screen md {
    width: 50vw;
    top: 4rem;
  }

  @screen xl {
    @apply static w-auto px-9 transition-none z-10;
    transition: none;
  }
}

.nav.opened {
  @apply left-0;

  @screen xl {
    @apply transform-none  z-50;
  }
}

@screen xl {
  .nav.transparent {
    @apply bg-transparent;
    transition: all 0.3s;
  }
  .nav.transparent strong {
    @apply text-primaryB;
  }
}

.list {
  @screen xl {
    @apply table-row;
  }
}
