.container {
  @apply flex flex-col justify-center items-center w-full relative my-0 mx-0 py-0 px-0;
  @screen md {
    margin: 4rem auto 0;
  }
}
.container.titleBorder {
  @apply border-t border-grey300 lg:border-primaryA;
}

.title {
  @apply text-sm font-inter font-medium uppercase text-primaryA bg-primaryB;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  line-height: 3.875rem;

  @screen md {
    @apply font-normal font-gilda normal-case;
    font-size: 1.5625rem;
    transform: translateY(-50%);
  }
}

.row {
  @apply flex flex-row justify-start items-start flex-wrap w-full relative py-0;
  margin: 0 1.5%;

  @screen md {
    @apply px-6;
  }
}
.col {
  @apply w-full box-border;
  margin-bottom: 2.8125rem;
  padding: 0 0.3125rem;

  @screen lg {
    margin-bottom: 3.125rem;
    @apply w-1/3;
  }
}
.col.half {
  @apply w-1/2 lg:w-1/3;
}

.loadMoreContainer {
  @apply relative mb-14 lg:mb-0;
}

.loadmore {
  @apply text-sm text-center text-primaryA;
  margin: 0.5rem 0 0;
}
.loadmore mark {
  @apply bg-transparent font-extrabold;
}

.seemoreButton {
  @apply w-full h-12 text-sm uppercase font-medium;
  @screen md {
    max-width: 27.125rem;
  }
}

.loading {
  min-height: 3rem;
}

.noPadding {
  @apply w-full m-0 px-0;
}
.noPadding .content {
  @apply mx-0;
}
.noPadding .row {
  @apply mx-0 px-0;
}
.noPadding .col {
  @apply p-0;
}

.navigationContainer {
  @apply flex flex-col-reverse lg:flex-row justify-center items-center w-full lg:mt-4;
}

.paginationTypeContainer {
  @apply flex flex-wrap items-center relative right-auto lg:absolute lg:right-0;
}
.paginationTypeContainer::after {
  @apply absolute right-4 bottom-4;
  content: '';
  width: 0.313rem;
  height: 0.313rem;
  box-shadow: 0.0625rem 0.0625rem 0 var(--primaryA);
  transform: rotate(45deg);
}

.labelSelector {
  @apply uppercase text-primaryA font-medium;
  font-size: 0.75rem;
}

.selector {
  @apply block relative border text-grey600 text-sm border-grey300 bg-primaryB cursor-pointer;
  margin: 0 0 0 0.315rem;
  padding: 0.45rem 1.575rem 0.45rem 0.625rem;
}
