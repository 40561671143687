.mediasContainer {
  @apply flex flex-row items-start justify-around relative;

  @screen md {
    @apply col-start-1 col-end-9;
  }
}

.miniaturesContainer {
  @apply hidden;
  width: 3.125rem;
  @screen md {
    @apply inline-block;
  }
}

.sliderContainer {
  max-width: 100%;
  @screen md {
    width: calc(100% - 5rem);
  }
}

.img {
  @apply object-cover;
}
