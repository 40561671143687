.container {
  @apply w-full h-full relative bg-primaryB;
}

.list {
  @apply w-full h-full relative overflow-scroll;
  padding-bottom: 3.125rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.list::-webkit-scrollbar {
  @apply hidden;
}

.action {
  @apply lg:hidden flex flex-row items-center w-full absolute bottom-0 bg-primaryB border-t border-grey300 z-30;
}

.button {
  @apply w-full uppercase font-medium text-center py-4 px-2.5 text-primaryA whitespace-nowrap;
}

.separator {
  @apply h-6 bg-grey300;
  width: 0.063rem;
}
