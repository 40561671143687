.container {
  @apply w-full relative mb-0;
}

.blockContainer > * {
  @apply p-0 m-0;
}

.jsDisabled {
  @apply flex flex-col;
}
.jsDisabledBlocks {
  @apply pb-3;
}

.control {
  @apply flex justify-center w-auto h-7 absolute bottom-16 md:bottom-14 right-1 md:right-5;
}
.dot {
  @apply p-2 cursor-pointer;
}
.dot span {
  @apply block w-3 h-3 bg-transparent  border-2 border-primaryB;
  border-radius: 50%;
  box-shadow: 0.2px 0.2px 0 rgb(0 0 0 / 70%);
}
.dot span:focus {
  outline: none;
}
.dot.active span {
  @apply bg-primaryB border-primaryB;
  box-shadow: 0.2px 0.2px 0 rgb(255 255 255 / 70%);
}

.scrollTo {
  @apply flex justify-center items-center relative left-1/2 bottom-0 bg-primaryB rounded-full cursor-pointer;
  width: 3rem;
  height: 3rem;
  box-shadow: rgb(69 50 42 / 5%) 0 0.3125rem 0.625rem;
  transform: translate(-50%, -50%);
  transition: background-color 0.5s;
}
[dir='rtl'] .scrollTo {
  right: calc(50% - 48px);
  left: unset;
}
.arrowDown {
  @apply block box-content relative;
  transform: rotate(90deg) translate(0.125rem, 0);
}
.scrollTo:hover {
  @apply bg-primaryA;
}
.scrollTo:hover .arrowDown path {
  stroke: white;
}

.container:not(:first-of-type) {
  @apply mb-12;
}
.container:not(:first-of-type) .scrollTo,
.isInListing .scrollTo {
  @apply hidden;
}
.container:not(:first-of-type) .control,
.isInListing .control {
  @apply bottom-3.5;
}
.container:first-of-type h1 {
  @apply w-full pb-2.5 font-gilda font-normal;
  font-size: 3.125rem !important;
  line-height: 4.0625rem !important;
  @screen md {
    font-size: 3.25rem !important;
    line-height: 3.875rem !important;
  }
}
.container:first-of-type p {
  @apply w-full mr-0 pb-2.5 font-inter text-xs  font-normal text-center uppercase;
  font-size: 0.75rem;
}
[dir='rtl'] .container:first-of-type p {
  @apply ml-0 mr-auto;
}

.isInListing {
  margin-bottom: 3.75rem;
  @screen lg {
    margin-bottom: 3.875rem;
  }
}
