.letterBlock {
  @apply lg:flex py-7 border-t-2 border-solid border-primaryA;
}

.letterBlock:first-child {
  @apply border-t-0;
}

.brandsBlock {
  @apply w-full;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  @screen lg {
    grid-template-columns: repeat(3, 1fr);
  }
}
@screen lg {
  .brandsBlock.searchDisabled li:nth-child(1) {
    @apply order-2;
  }
  .brandsBlock.searchDisabled li:nth-child(2) {
    @apply order-1;
  }
  .brandsBlock.searchDisabled li:nth-child(n + 3) {
    @apply order-3;
  }
}

.letter {
  @apply flex justify-center items-center w-full font-gilda text-primaryA;
  font-size: 4.625rem;
  line-height: 4.625rem;
}

.brand {
  @apply h-60 lg:h-80 w-full relative bg-grey300 overflow-hidden;
}
.searchDisabled .brand {
  grid-row: span 2;
}
.brand a {
  @apply flex justify-center items-end w-full h-full relative font-inter text-sm text-primaryA;
}

.brandMedia {
  @apply absolute z-0;
}

.brandName {
  @apply mb-5 px-12 py-3 uppercase bg-primaryB;
  z-index: 5;
}

.brandDesc {
  @apply hidden flex-col justify-end items-center w-full h-full absolute py-5 px-2.5 bg-primaryB border  border-primaryA;
  z-index: 8;
}
.brandDescName {
  @apply font-gilda text-xl uppercase;
}
.brandDescText {
  @apply py-5 lg:py-10 text-center;
}
.brandDescCta {
  @apply px-12 py-3 text-primaryB bg-primaryA uppercase;
}

.brandLink:hover .brandDesc,
.brandLink:focus .brandDesc {
  @apply lg:flex;
}

.brandSuffix {
  @apply inline-block font-inter text-xxs font-medium ml-1 normal-case;
  padding: 0.15625rem 0.3125rem;
  border-radius: 0.1875rem;
  transform: translateY(-0.0625rem);
}

[dir='rtl'] .brandSuffix {
  @apply mr-1 ml-0;
}

.brandIsNew {
  @apply bg-primaryA text-primaryB;
}

.brandIsNewDesc {
  @apply absolute top-2.5 right-2.5;
}

.brandDiscount {
  @apply bg-gray-100 text-primaryA;
}

.displayNone {
  @apply hidden;
}

.anchor {
  @apply relative;
}
