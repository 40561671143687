.container {
  @apply flex flex-col items-center w-full box-border relative;
  margin: 1.875em auto 3.5rem;
  @screen md {
    margin: 0.5rem auto 1.25rem;
  }
}

.mediaContainer {
  @apply relative mb-9 w-full;
}
.mediaContainer img {
  @apply block w-full;
}
.descriptionContainer {
  @apply w-full px-5;
}

@screen md {
  .container.layout_left {
    @apply flex-row;
  }
  .container.layout_right {
    @apply flex-row-reverse;
  }
  .container .mediaContainer,
  .container .descriptionContainer {
    @apply w-full;
  }
  .container.layout_left .description,
  .container.layout_right .description {
    flex: 1;
  }
  .container.layout_left .mediaContainer,
  .container.layout_right .mediaContainer {
    flex: 1.2;
    width: 62%;
  }
  .container.layout_left .descriptionContainer,
  .container.layout_right .descriptionContainer {
    width: 38%;
  }
}
