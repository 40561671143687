.container {
  @apply flex flex-col items-center w-full box-border relative block-cms-container-mobile md:w-1/2;
  @screen md {
    @apply block-cms-container-tablet;
  }
  @screen xl {
    @apply block-cms-container-desktop;
  }
}

.media {
  @apply block w-full;
}
.media img {
  @apply block w-full;
  transition: transform 1s;
  transform: scale(1);
}

.description {
  @apply w-full relative py-2.5 bg-primaryB;

  @screen md {
    @apply absolute py-8 px-5;
    max-width: 75%;
    margin: 10% 0;
  }
}
.layout_top .description {
  @apply top-0;
}
.layout_bottom .description {
  @apply bottom-0;
}

@media screen and (min-width: 1510px) {
  .container {
    max-height: 87.5rem;
  }
}
