.root {
  @apply relative z-40 h-14;
  @screen md {
    @apply h-16;
  }
  @screen xl {
    @apply h-auto;
  }
}

.root.expand {
  height: 7rem;
  @screen md {
    @apply h-32;
  }
  @screen lg {
    @apply h-16;
  }
  @screen xl {
    @apply h-auto;
  }
}

.header {
  @apply fixed h-14 top-0 right-0 left-0 bg-primaryB z-20;
  @screen md {
    @apply h-16;
  }
  @screen xl {
    @apply static h-auto bg-transparent;
  }
}
.header.headerTop {
  @apply relative xl:static;
}

.container {
  @apply relative flex flex-nowrap h-full items-center justify-center  py-3 px-4;

  @screen lg {
    @apply pt-5 pb-3 px-11;
  }
}

.headerLeft {
  @apply flex-1 flex flex-row items-center;
}

.burgerMenu {
  @apply mr-2;
}

[dir='rtl'] .burgerMenu {
  @apply ml-2 mr-0;
}

.headerCenter {
  @apply flex flex-col justify-center items-center;
}

.headerCenter.displayNone {
  @apply hidden;
}

.logo {
  @apply h-auto;
  width: 8.75rem;

  @screen lg {
    width: 14.375rem;
  }
}

.inputContainer {
  @apply flex h-10 bg-primaryB mx-4;
}

.inputContainer.opacity {
  background-color: rgba(255, 255, 255, 0.3);
}

.inputContainer.searchUnderHeader {
  @apply absolute top-14 md:top-16  flex justify-center items-center w-full px-4 py-2 h-14;
}

.inputBox {
  @apply relative w-full h-10 flex items-center  px-4;
  border-radius: 50px;
  background-color: #f3f3f3;
}

.input {
  @apply w-full  h-full font-inter text-base text-gray-600 bg-transparent rounded ml-2 overflow-ellipsis;
}
.input::placeholder {
  @apply text-gray-600;
}

[dir='rtl'] .input {
  @apply mr-2 ml-0;
}

.clearCross {
  @apply cursor-pointer;
}

.searchBar {
  @apply mx-3.5;
}

.catchPhrase {
  @apply hidden font-lora text-primaryA text-xxs;

  @screen xl {
    @apply block;
  }
}

.headerRight {
  @apply flex-1 flex flex-row items-center justify-end;

  @screen xl {
    @apply flex-row items-start;
  }
}

.transparent .catchPhrase,
.transparent .customerService,
.transparent a,
.transparent span,
.transparent input[type='text'] {
  @apply text-primaryB;
}

.transparent .headerRight path {
  stroke: #fff;
  stroke-width: 2;
}
.transparent .searchBar path {
  stroke-width: 1;
}
