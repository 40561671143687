.grid {
  @apply flex flex-row justify-start w-full py-5;
  max-width: 100rem;
  @screen lg {
    padding: 3.75rem 0;
  }
}

.social {
  @apply w-full lg:w-1/3;
}

.blockLinks {
  @apply flex-1 pr-4;
}
