@keyframes slideInUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.selectedContent {
  @apply relative border border-b-0 h-12 flex items-center justify-between w-full;
  border-color: var(--grey300);
  padding: 0.375rem 0.5rem 0.375rem 1rem;
}

.selectedContent::after {
  content: '';
  @apply absolute bottom-0 left-0 w-full border-b;
  border-color: var(--grey300);
}
[dir='rtl'] .selectedContent {
  padding: 0.375rem 1rem 0.375rem 0.5rem;
}

.selectedContent.expanded::after {
  @apply md:border-transparent;
}

.buttonContent {
  @apply w-full text-left;
  font-size: 0.75rem;
}

.placeholder {
  @apply font-inter text-sm text-primaryA;
}

.selectIconContainer {
  @apply ml-3;
}

[dir='rtl'] .selectIconContainer {
  @apply mr-3 ml-0;
}

.chevron {
  @apply h-4 w-4;
  transition: transform 0.3s;
}

.chevronDown {
  transform: rotate(180deg);
}

.backdrop {
  @apply fixed z-40 inset-0;
  background-color: var(--backdrop);
  animation: fadeIn 0.4s linear forwards;

  @screen md {
    @apply hidden;
  }
}

.selectContainer {
  @apply fixed bottom-0 right-0 w-full px-4  z-50;
  max-height: 100vh;
  background-color: var(--primaryB);
  @screen md {
    @apply relative p-0;
    border-color: var(--grey300);
  }
  animation: slideInUp 0.6s ease-out forwards;
}

.selectHeader {
  @apply py-4 flex justify-between items-center border-b border-grey200 md:border-0;
  @screen md {
    @apply hidden;
  }
}

.headerTitle {
  @apply text-sm text-grey600;
}

.headerIcon {
  color: var(--primaryA);
}

.selectOptions {
  @apply overflow-y-scroll px-0 pb-8;
  max-height: calc(100vh - 10rem);

  @screen md {
    @apply absolute w-full border border-t-0 pb-0;
    max-height: 20rem;
    background-color: var(--primaryB);
    border-color: var(--grey300);
  }
}
.selectOptions::-webkit-scrollbar {
  width: 0.4375rem;
}

.selectOptions::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: rgba(0, 0, 0, 0);
}

.selectOptions:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.option {
  @apply h-12 flex items-center cursor-pointer;
  padding: 0.625rem 0.375rem;
  font-size: 0.75rem;

  @screen md {
    padding: 0.375rem 0.5rem 0.375rem 1rem;
  }
}

.option + .option {
  @apply border-t md:border-0;
  border-color: var(--grey500);
}

.option.focused {
  background-color: var(--grey200);
}
