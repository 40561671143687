.container {
  @apply flex p-0 w-full justify-between mx-auto;
  margin-top: -2.5rem;

  @screen md {
    height: 60vh;
    min-width: 52rem;
    min-height: 32.875rem;
  }
}
