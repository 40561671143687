.button {
  @apply flex justify-center items-center p-2 md:p-4 xl:hidden;
}

.bar {
  @apply relative block h-px bg-primaryA;
  width: 1.375rem;
  transition: height 0.2s;
}
.bar::after,
.bar::before {
  @apply absolute w-full h-px left-0 bg-primaryA;
  content: '';
  transition: transform 0.3s ease-in-out;
}
.bar::after {
  bottom: 0.5rem;
}
.bar::before {
  bottom: -0.5rem;
}

.opened .bar {
  @apply h-0;
}
.opened .bar::after {
  @apply top-1/2;
  transform: rotate(45deg);
}
.opened .bar::before {
  @apply top-1/2;
  transform: rotate(-45deg);
}
