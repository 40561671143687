.container {
  @apply hidden flex-row justify-between items-start flex-1 lg:px-2.5;
}
.container.enable {
  @apply flex;
}

.heading {
  @apply hidden lg:block;
  padding: 0.5625rem 0;
}
.list {
  @apply flex flex-row justify-start items-center flex-1 flex-wrap px-2 overflow-x-auto overflow-y-hidden;
}
.list::-webkit-scrollbar {
  height: 0.4375rem;
}
.list::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: rgba(0, 0, 0, 0);
}
.list:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.buttons {
  @apply flex flex-row justify-end items-center;
}

.filter {
  @apply flex flex-row justify-between items-center my-0.5 mx-1.5  py-1.5 px-2 font-inter border border-grey300 cursor-pointer;
}
.filter:hover {
  @apply bg-primaryA text-primaryB;
  transition: background-color 0.5s, color 0.5s;
}

.cross {
  @apply h-2.5 w-2.5 mt-0.5 ml-1;
}

[dir='rtl'] .cross {
  @apply mr-1 ml-0;
}

.erase {
  @apply ml-2 py-1.5;
}

[dir='rtl'] .erase {
  @apply mr-2 ml-0;
}

.clear {
  @apply hidden;
}
.clear.enable {
  @apply block;
}
