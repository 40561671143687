.title {
  @apply mt-2 text-primaryA text-base font-medium uppercase;
  font-size: 0.875rem;
  padding-bottom: 1.3125rem;
  border-color: var(--grey300);
}

.items {
  @apply flex flex-col;
  margin-top: 1.125rem;
}

.item {
  @apply font-medium text-xs uppercase text-left;
  color: var(--grey600);
}

.item:hover {
  color: var(--primaryA);
}

.item + .item {
  margin-top: 0.3125rem;
}
