.container {
  @apply flex justify-center items-center w-full md:w-1/2 relative cursor-pointer;
}

.img {
  @apply w-full h-full mx-auto my-0;
  max-width: 45rem;
}

.icon {
  @apply w-full absolute bottom-2;
}

.cta {
  @apply flex flex-row justify-center items-center w-full  relative mt-3.5 py-5 px-2.5 border-primaryA border-t border-b;
}
.ctaLabel {
  @apply ml-2 uppercase;
}

[dir='rtl'] .ctaLabel {
  @apply mr-2 ml-0;
}
