.container {
  @apply bg-grey300 w-full;
  border-radius: 0.625rem;
  height: 0.188rem;
  margin: 0.625rem auto 1.25rem;

  @screen md {
    width: 21.438rem;
  }
}

.bar {
  @apply bg-primaryA h-full;
  border-radius: 0.625rem;
}
