.list {
  @apply flex items-center;
}

.vertical {
  @apply h-auto flex-col;
}

.listItem {
  @apply flex;
}

.link {
  @apply flex justify-center items-center w-12 h-12 mx-2.5 lg:mr-5 lg:ml-0 p-3 border border-primaryB;
  transition: all 0.3s;
}

[dir='rtl'] .link {
  @apply lg:mr-0 lg:ml-5;
}

.link svg {
  @apply h-6;
}
.link path {
  transition: all 0.3s;
}

.link:hover {
  @apply border-grey300;
}
.link:hover path {
  fill: var(--grey500);
}
